import React, { useState, useContext, useEffect } from 'react';
import { Typography, Box, Button, Paper } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useHistory } from 'react-router-dom';
import ROUTES from 'src/constants/routes';
import useButtonStyle from 'src/styles/button';
import MzButton from 'src/components/MzButton';
import { Formik, Form } from 'formik';
import FormTextField from 'src/components/FormTextField/FormTextField.js';
import * as Yup from 'yup';
import useBreakpoints from 'src/hooks/useBreakpoints';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import FormDropdown from 'src/components/FormDropdown/FormDropdown.js';
import AlertBox from 'src/components/AlertBox';
import fourSteps from 'src/assets/requests/4steps.png';
import { useProvideRequest } from 'src/hooks/useRequests';
import { useProvideProduct } from 'src/hooks/useProduct';
import useSnackbar from 'src/hooks/useSnackbar';
import { ConfigContext } from 'src/contexts';
import GetStartedLayout from 'src/components/Corpsec/Requests/GetStartedLayout';

const requestType = [
  { label: 'Capital & Shares Related' },
  { label: 'General Enquires' },
  { label: 'Officers Related' },
  { label: 'Product & Subscription Related' },
  { label: 'Shareholders Related' },
];

const OtherRequest = ({ toggleShowMenu }) => {
  const history = useHistory();
  const [showAlert, setShowAlert] = useState(false);
  const buttonClasses = useButtonStyle();
  const [step, setStep] = useState(1);
  const { smBelow } = useBreakpoints();
  const {
    CreateOtherRequestApplication,
    requestError,
    requestSubmitting,
  } = useProvideRequest();
  // const {
  //   GetFinancialYearEnd,
  //   GetAGM,
  //   agm,
  //   financialYearEnd,
  //   companyError,
  // } = useProvideCompany();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const {
    addCart,
    user,
    cartSubmitting,
    company,
    setConfirmationDialog,
  } = useContext(ConfigContext);
  const { ReadProduct, product, productError } = useProvideProduct();

  const getStarted = {
    one: {
      content: [
        'If you are still unable to find the secretarial service you need, you can use this page to submit a request to us',
        'We will review your request and contact you for any clarifications',
      ],
    },
    two: {
      content: [
        'Information and/or documents that you think will help us process your request faster',
      ],
    },
    three: {
      content: [
        'Create Request',
        'Meyzer360 Review',
        'Sign Resolution',
        'ACRA Filing',
      ],
    },
    four: {
      content: [
        'Fee will vary depending on the complexity of service requested',
      ],
    },
    five: {
      content: ['Fill up form: < 5 minutes', 'Average processing time: Varies'],
    },
  };

  const validationSchema = Yup.object({
    type: Yup.string().required('Type is required'),
    description: Yup.string().required('Description is required'),
  });

  useEffect(() => {
    // get latest product
    ReadProduct(19);
  }, []);

  // useEffect(() => {
  //   if (companyError) {
  //     showErrorSnackbar(
  //       `Error loading Company's other request. ${companyError}`,
  //     );
  //   }
  // }, [companyError]);

  useEffect(() => {
    if (productError) {
      showErrorSnackbar(`Error loading product. ${productError}`);
    }
  }, [productError]);

  useEffect(() => {
    if (requestError) {
      showErrorSnackbar(`Error submitting application. ${requestError}`);
    }
  }, [requestError]);

  const formikOnSubmit = async (values, actions) => {
    values.company_id = company.company_id;
    values.company_application_status_id = 11;
    values.created_by = user.user_id;

    let res = await CreateOtherRequestApplication(values);
    if (res.status === 'success') {
      res = await addCart({
        price: product.price,
        product_id: product.id,
        quantity: 1,
        user_id: user.user_id,
        product_detail_id: res.body.applications.other_request_application_id,
        company_id: company.company_id,
        company_name: company.company_name,
        company_registration_no: company.company_registration_no,
        company_application_id: res.body.applications.company_application_id,
      });
      if (res.status === 'success') {
        actions.resetForm();
        setConfirmationDialog({
          isVisible: true,
          title: 'Item added to cart',
          type: 'success',
          cancelButtonLabel: 'Back to Request',
          confirmButtonLabel: 'View your cart',
          confirmButtonAction: () => {
            history.push(ROUTES.PAYMENTCART);
            setConfirmationDialog({
              isVisible: false,
            });
          },
          cancelButtonAction: () => {
            history.push(ROUTES.CORPSEC_REQ);
            setConfirmationDialog({
              isVisible: false,
            });
          },
        });
      }
    }
  };

  const stepTwo = (props) => {
    return (
      <Formik
        initialValues={{
          type: '',
          description: '',
        }}
        onSubmit={formikOnSubmit}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form style={{ width: '100%' }}>
            <FormDropdown
              autoCompleteStyle={{ width: smBelow ? '100%' : '50%' }}
              name="type"
              onChange={(event, newValue) => {
                props.setFieldValue('type', newValue?.label);
              }}
              optionList={requestType}
              labelText="Type *"
              placeholder="Select Type"
              fullWidth
              containerWidth="50%"
              shrink
              variant="outlined"
            />
            <FormTextField
              name="description"
              labelText="Description *"
              placeholder="Enter Description"
              shrink
              variant="outlined"
              multiline
              rows={10}
              fullWidth={smBelow ? true : false}
            />
            <Box display="flex" pt={3} justifyContent="flex-start">
              <MzButton
                fullWidth={smBelow ? true : false}
                type="submit"
                title={'SUBMIT'}
                onClick={() => {
                  setStep(2);
                }}
                loading={cartSubmitting || requestSubmitting}
              />
            </Box>
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <>
      <GetStartedLayout
        toggleShowMenu={toggleShowMenu}
        step={step}
        setStep={setStep}
        getStarted={getStarted}
        header={
          <>
            <Typography variant="h2">
              {step === 1 && 'Other Requests'}
              {step === 2 && 'Fill in Information for Other Request'}
            </Typography>
            <BreadcrumbsNav
              pathList={[
                { path: 'Requests', route: ROUTES.CORPSEC_REQ },
                {
                  path: 'Filing of Other Resolutions',
                  route: ROUTES.CORPSEC_REQ_FILLING_OTHER_RESOLUTIONS,
                },
                {
                  path: 'Other Request',
                  color: 'primary',
                },
              ]}
            />
          </>
        }
      >
        {step === 2 && (
          <>
            <Box px={4} py={4} style={{ borderTop: '0.01rem solid #E4E4E4' }}>
              {stepTwo()}
            </Box>
          </>
        )}
        <Box py={5} />
      </GetStartedLayout>
    </>
  );
};

export default OtherRequest;
