import axios from './customAxios';
import { Config } from 'src/config';
import helper from 'src/helper';

const timeline = async (companyId) => {
  const { data } = await axios.get(
    `${Config.apiURL}/company/timeline/company/${companyId}`,
  );

  if (data && !data.is_success) throw Error(data?.message);

  return helper.isEmpty(data?.timeline) ? [] : data?.timeline;
};

const timelineSvc = { timeline };

export default timelineSvc;
