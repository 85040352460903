import React, { useEffect, useState } from 'react';
import { useProvideCart } from 'src/hooks/useCart';
import useSnackbar from 'src/hooks/useSnackbar';
import authSvc from 'src/services/authentication';
import { useLocation } from 'react-router-dom';
import companySvc from 'src/services/company';

export const ConfigContext = React.createContext({});

export const ConfigContextProvider = ({ children }) => {
  const auth = authSvc.getAuthData();
  const location = useLocation();

  const clearCart = () => {
    setConfigState((prevState) => {
      return {
        ...prevState,
        cart: {},
        cartList: [],
      };
    });
  };

  const fetchCart = (user) => {
    ListCart(user.user_id)
      .then((res) => {
        const initState = {};
        if (res.status === 'success') {
          initState.cartList = res.body.cart?.items;
          initState.cart = res.body.cart;
          setConfigState((prevState) => {
            return {
              ...prevState,
              cart: res.body.cart || {},
              cartList: res.body.cart?.items || [],
            };
          });
        }
      })
      .catch((e) => {
        setConfigState((prevState) => {
          return {
            ...prevState,
          };
        });
      });
  };

  const setUser = (user) => {
    if (user.user_id) {
      fetchCart(user);
    }
    setConfigState((prevState) => {
      return {
        ...prevState,
        user: user,
      };
    });
  };

  const {
    AddCart,
    DeleteCartItem,
    ListCart,
    cartError,
    cartSubmitting,
  } = useProvideCart();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();

  useEffect(() => {
    if (cartError) {
      showErrorSnackbar(cartError);
    }
  }, [cartError]);

  useEffect(() => {
    setConfigState((prevState) => {
      return {
        ...prevState,
        cartSubmitting,
      };
    });
  }, [cartSubmitting]);

  useEffect(() => {
    if (auth != undefined && auth.is_success === true) {
      const urlSearchParams = new URLSearchParams(location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      if (!params.redirect || params.redirect !== 'checkout') {
        // Do not fetch cart if redirected back from payment gateway, due to delay in callback, cart is still not cleared
        fetchCart(auth.user);
      }
    }
  }, []);

  const addWebInfo = (webInfo) => {
    setConfigState((prevState) => {
      delete prevState.webInfo.webdev_category_name;
      return {
        ...prevState,
        webInfo: { ...prevState.webInfo, ...webInfo },
      };
    });
  };

  const addCart = async (cart, successMsg) => {
    const auth = authSvc.getAuthData();
    cart.last_name = auth.user.last_name;
    cart.first_name = auth.user.first_name;
    cart.email = auth.user.email;
    const res = await AddCart(cart);
    if (res.status === 'success') {
      setConfigState((prevState) => ({
        ...prevState,
        cartList: [...prevState.cartList, res.body.cart],
        cart: res.body,
      }));
    }
    return res;
  };

  const removeCart = async (cartList, index) => {
    const itemToRemove = cartList[index];
    const res = await DeleteCartItem(itemToRemove.id);
    if (res.status === 'success') {
      cartList.splice(index, 1);
      setConfigState((prevState) => ({
        ...prevState,
        cartList: cartList,
      }));
      showSuccessSnackbar('Item removed from cart.');
    }
    return res;
  };

  const setConfirmationDialog = (dialogState = {}) => {
    setConfigState((prevState) => {
      return {
        ...prevState,
        confirmationDialog: Object.assign({}, dialogState),
      };
    });
  };

  const setCompany = async (companyId) => {
    setConfigState((prevState) => {
      let newCompany = prevState.companyList.find(
        (item) => item.company_id === companyId,
      );
      companySvc.setCompany({
        companyName: newCompany.company_name,
        companyId: newCompany.company_id,
        uen: newCompany.company_registration_no,
      });
      return {
        ...prevState,
        company: newCompany,
      };
    });
  };

  //   const setCompanyList = async () => {
  //     setConfigState((prevState) => ({
  //       ...prevState,
  //       companyLoading: true,
  //     }));
  //     const res = await companySvc.companiesAndShares();
  //     let newState = {};
  //     if (res.length > 0) {
  //       newState = {
  //         company: res[0],
  //         companyList: res,
  // <<<<<<< HEAD
  //       }));
  //     }

  //     // move out for no company conditions
  //     setConfigState((prevState) => ({
  //       ...prevState,
  // =======
  //       };
  //     }
  //     setConfigState((prevState) => ({
  //       ...prevState,
  //       ...newState,
  // >>>>>>> a7563ce06f9cdfbe07262f02d85e5d2e64ec4ec9
  //       companyLoading: false,
  //     }));
  //     return res;
  //   };

  const setCompanyList = async () => {
    setConfigState((prevState) => ({
      ...prevState,
      companyLoading: true,
    }));
    const res = await authSvc.getSysinfoUserCompanies();
    // const res = await companySvc.companiesAndShares();
    if (res.length > 0) {
      setConfigState((prevState) => {
        companySvc.setCompany({
          companyName: res[0]?.company_name,
          companyId: res[0]?.company_id,
          uen: res[0]?.company_registration_no,
        });
        return {
          ...prevState,
          company: res[0],
          companyList: res,
        };
      });
    }

    // move out for no company conditions
    setConfigState((prevState) => ({
      ...prevState,
      companyLoading: false,
    }));
    return res;
  };

  const [configState, setConfigState] = useState({
    cartList: [],
    cart: {},
    cartSubmitting,
    confirmationDialog: {
      title: '',
      isVisible: false,
      onBackdropPress: () => {},
      confirmButtonAction: () => {},
      cancelButtonAction: () => {},
      confirmButtonLabel: '',
      cancelButtonLabel: '',
      confirmButtonStyles: {},
      cancelButtonStyles: {},
      type: '',
      showIcon: true,
      icon: '',
      maxWidth: 'sm',
    },
    webInfo: [],
    user: auth?.user || {},
    addCart,
    clearCart,
    removeCart,
    addWebInfo,
    setConfirmationDialog,
    setUser,
    fetchCart,
    company: {},
    companyList: [],
    companyLoading: false,
    setCompany,
    setCompanyList,
  });

  useEffect(() => {
    if (auth && configState.companyList.length === 0) {
      setCompanyList();
    }
  }, [auth && configState.companyList.length]);

  return (
    <ConfigContext.Provider value={configState}>
      {children}
    </ConfigContext.Provider>
  );
};
