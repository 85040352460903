import React, { useState, useEffect, useRef } from 'react';
import {
  Typography,
  Box,
  Button,
  ButtonBase,
  Divider,
  Grid,
  Paper,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import LoadingOverlay from 'react-loading-overlay';
import ClipLoader from 'react-spinners/ClipLoader';

import MzButton from '../MzButton';
import CustomTextField from '../CustomFormFields/CustomTextField';
// import CustomSelectField from '../CustomFormFields/CustomSelectField';
import FormFieldContainer from '../CustomFormFields/FormFieldContainer';
import utilsSvc from '../../services/utilsApi';
import authApi from '../../services/authentication';
import { useProvideContactSupportTable } from 'src/hooks/useContactSupport';
import useSnackbar from 'src/hooks/useSnackbar';

const useStyles = makeStyles((theme) => ({
  fontWeight: {
    fontWeight: 600,
  },
  background: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    zIndex: -2,
    backgroundPosition: 'center',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  bgroot: {
    color: 'white',
    position: 'relative',
    display: 'flex',
    zIndex: 0,
    height: '20vh',
  },
  tab: {
    textTransform: 'initial',
    fontSize: 16,
  },
  disabledText: {
    paddingLeft: '5px',
  },
  changeDetails: {
    color: '#888B94',
  },
  gridItem: {
    marginBottom: theme.spacing(2),
  },
  paperContainer: {
    padding: theme.spacing(4),
  },
}));

const fields = {
  fieldName_name: {
    label: 'Name',
    name: 'username',
  },
  fieldName_email: {
    label: 'Email Address',
    name: 'email',
  },
  fieldName_subject: {
    label: 'Subject',
    name: 'subject',
  },
  fieldName_message: {
    label: 'Message',
    name: 'message',
  },
};
const {
  fieldName_name,
  fieldName_email,
  fieldName_subject,
  fieldName_message,
} = fields;

const validationSchema = Yup.object({
  [fieldName_name.name]: Yup.string().required('Name is required').default(''),
  [fieldName_email.name]: Yup.string()
    .required('Email is required')
    .default(''),
  [fieldName_subject.name]: Yup.string()
    .required('Subject is required')
    .default(''),
  [fieldName_message.name]: Yup.string().default(''),
});

const ContactSupport = (props) => {
  const classes = useStyles();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();

  const {
    error: createContactSupportError,
    loading: createContactSupportLoading,
    SubmitContactSupportRequest,
  } = useProvideContactSupportTable();

  useEffect(() => {
    if (createContactSupportError) {
      showErrorSnackbar(
        `Error requesting contact support. ${createContactSupportError}`,
      );
    }
  }, [createContactSupportError]);

  const defaultValues = validationSchema.cast();
  const handleOnSubmit = (values) => {
    let userData = authApi.getLocalUser();
    if (userData?.sysInfo_user_id) {
      const body = {
        ...values,
        sysinfo_user_id: userData.sysInfo_user_id,
        created_by: userData.sysInfo_user_id,
        status: 'pending',
      };
      utilsSvc
        .createContactSupport(body)
        .then((result) => {
          showSuccessSnackbar('Your request has been submitted successfully.');
        })
        .catch((error) => {
          showErrorSnackbar(
            'Failed to submit your request, please try again later.',
          );
        });
    }
  };
  return (
    <LoadingOverlay
      active={createContactSupportLoading}
      spinner={<ClipLoader color="white" />}
      styles={{
        wrapper: {
          overflow: createContactSupportLoading ? 'hidden' : 'initial',
        },
      }}
    >
      <Paper
        elevation={0}
        variant="outlined"
        style={{
          borderRadius: '10px',
        }}
        className={classes.paperContainer}
      >
        <Box pb={4}>
          <Box>
            <Typography variant="h3" className={classes.fontWeight}>
              Submit your request
            </Typography>
          </Box>
          <Box>
            Your request will be processed and our support staff will get back
            to you in 24 hours.
          </Box>
        </Box>
        <Box>
          <Formik
            initialValues={defaultValues}
            validationSchema={validationSchema}
            onSubmit={() => {}}
          >
            {(props) => {
              const {
                validateForm,
                values,
                setTouched,
                touched,
                handleSubmit,
                setValues,
              } = props;

              return (
                <Form style={{ height: '100%' }}>
                  <Grid container>
                    <Grid container item xs={12} md={6}>
                      <Grid item xs={12} className={classes.gridItem}>
                        <CustomTextField
                          label={`${fieldName_name.label} *`}
                          name={fieldName_name.name}
                        />
                      </Grid>
                      <Grid item xs={12} className={classes.gridItem}>
                        <CustomTextField
                          label={`${fieldName_email.label} *`}
                          name={fieldName_email.name}
                        />
                      </Grid>
                      <Grid item xs={12} className={classes.gridItem}>
                        <CustomTextField
                          label={`${fieldName_subject.label} *`}
                          name={fieldName_subject.name}
                        />
                      </Grid>
                      <Grid item xs={12} className={classes.gridItem}>
                        <CustomTextField
                          label={`${fieldName_message.label}`}
                          name={fieldName_message.name}
                          multiline
                          rows={4}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Button
                          // type="submit"
                          variant="contained"
                          color="primary"
                          style={{
                            minWidth: '180px',
                            minHeight: '60px',
                            textTransform: 'unset',
                          }}
                          onClick={() => {
                            validateForm()
                              .then((result) => {
                                console.log('validateForm result', result);
                                setTouched({
                                  ...touched,
                                  ...result,
                                });
                                let canSubmit = false;
                                if (result && Object.keys(result).length == 0) {
                                  canSubmit = true;
                                }
                                if (canSubmit) {
                                  // handleOnSubmit(values, canSubmit);
                                  let userData = authApi.getLocalUser();
                                  if (userData?.sysInfo_user_id) {
                                    const body = {
                                      ...values,
                                      sysinfo_user_id: userData.sysInfo_user_id,
                                      created_by: userData.sysInfo_user_id,
                                      status: 'pending',
                                    };
                                    SubmitContactSupportRequest(body)
                                      .then((result) => {
                                        showSuccessSnackbar(
                                          'Your request has been submitted successfully.',
                                        );
                                        setValues(defaultValues);
                                        setTouched({});
                                      })
                                      .catch((error) => {
                                        showErrorSnackbar(
                                          'Failed to submit your request, please try again later.',
                                        );
                                      });
                                  }
                                }
                              })
                              .catch((errors) => {
                                console.log('validateForm error', errors);
                                setTouched({ ...touched, ...errors });
                              });
                          }}
                        >
                          Submit
                        </Button>
                        {/* <MzButton
                        type="submit"
                        title={'Submit'}
                        onClick={() => {
                          validateForm()
                            .then((result) => {
                              console.log('validateForm result', result);
                              setTouched({
                                ...touched,
                                ...result,
                              });
                              let canSubmit = false;
                              if (result && Object.keys(result).length == 0) {
                                canSubmit = true;
                              }
                              if (canSubmit) {
                                handleOnSubmit(values, canSubmit);
                              }
                            })
                            .catch((errors) => {
                              console.log('validateForm error', errors);
                              setTouched({ ...touched, ...errors });
                            });
                        }}
                      /> */}
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Paper>
    </LoadingOverlay>
  );
};

export default ContactSupport;
