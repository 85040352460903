import React from 'react';
import { Link, Breadcrumbs } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import useStyles from './BreadcrumbsNav.styles';
import { useHistory } from 'react-router-dom';

const BreadcrumbsNav = ({ pathList, styles }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Breadcrumbs separator={'/'} style={styles}>
      {pathList.map((list, index) => {
        if (list.component) return list.component;
        const currentPath = pathList.length - 1 === index; // get the last path
        const routable = !currentPath && list.route;
        const color = list?.color || 'default';
        return (
          <Link
            key={index}
            color={color}
            style={{
              cursor: routable ? 'pointer' : 'auto',
              textDecoration: 'none',
            }}
            onClick={() => routable && history.push(list.route)}
            variant="h6"
          >
            {list.path}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default BreadcrumbsNav;
