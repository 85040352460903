import React from 'react';
import { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import useStyles from './FileDropzone.styles';
import { Button, Typography, Box } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const FileDropzone = ({
  multiple = false,
  files = [],
  setFiles,
  error,
  disabled,
  ...props
}) => {
  const classes = useStyles();

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    noKeyboard: true,
    multiple,
  });

  useEffect(() => {
    if (setFiles) {
      setFiles(acceptedFiles);
    }
  }, [acceptedFiles]);

  function bytesToSize(bytes) {
    const units = ['byte', 'kilobyte', 'megabyte', 'terabyte', 'petabyte'];
    const unit = Math.floor(Math.log(bytes) / Math.log(1024));
    return new Intl.NumberFormat('en', {
      style: 'unit',
      unit: units[unit],
    }).format(bytes / 1024 ** unit);
  }

  return (
    <>
      <section className={error ? classes.errorContainer : classes.container}>
        {files.length === 0 && (
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} disabled={disabled} />
            <Box
              px={2}
              display="flex"
              flexDirection="row"
              alignItems="center"
              p={3}
            >
              <CloudUploadIcon fontSize={'large'} />
              <Box display="flex" flexDirection="column" px={2}>
                <div>
                  <Typography color="textSecondary">
                    Drag and drog here / Click to upload
                  </Typography>
                </div>
                <Box mt={2}>
                  <Typography color="textSecondary">
                    Accepted formats: .jpg / .pdf / .png
                  </Typography>
                </Box>
                <Typography color="textSecondary">Click to Upload</Typography>
              </Box>
            </Box>
          </div>
        )}
        {files.length > 0 &&
          (multiple ? (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              // alignItems="center"
              minHeight={'50px'}
              flexShrink={1}
            >
              {files.map((aFile, index) => {
                return (
                  <Box key={index} px={3} mb={2}>
                    <Box>
                      <Typography variant="overline">{aFile.name}</Typography>
                      <Typography variant="overline">
                        &nbsp;{bytesToSize(aFile.size)}
                      </Typography>
                    </Box>
                    <Button
                      variant="outlined"
                      size="small"
                      style={{
                        color: '#900E1A',
                        border: '1px solid #900E1A',
                      }}
                      onClick={() => {
                        if (setFiles) {
                          setFiles([...files].splice(index, 1), true);
                        }
                      }}
                    >
                      Remove
                    </Button>
                  </Box>
                );
              })}
            </Box>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              minHeight={'50px'}
              flexShrink={1}
            >
              <Typography variant="overline">{files[0].name}</Typography>
              <Typography variant="overline">
                {bytesToSize(files[0].size)}
              </Typography>
              <Button
                variant="outlined"
                style={{
                  color: '#900E1A',
                  border: '1px solid #900E1A',
                }}
                onClick={() => {
                  if (setFiles) {
                    setFiles([], true);
                  }
                }}
              >
                Remove
              </Button>
            </Box>
          ))}
      </section>
      {error && (
        <Typography variant="caption" className={classes.errorText}>
          File is required
        </Typography>
      )}
    </>
  );
};

export default FileDropzone;
