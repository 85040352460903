import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Divider,
  Button,
  Container,
  Grid,
  Paper,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Checkbox,
  FormControlLabel,
  MenuItem,
  TextField,
  Avatar,
  Link,
  Card,
  CardContent,
} from '@material-ui/core';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import { Formik, Form, Field } from 'formik';
import yellow from '@material-ui/core/colors/yellow';
import cyan from '@material-ui/core/colors/cyan';
import clsx from 'clsx';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import useBreakpoints from 'src/hooks/useBreakpoints';
import commonSvc from '../../services/common';
import companySvc from '../../services/company';
import { S3UploadAndInsertDocuments } from '../../utils/aws';
import ROUTES from '../../constants/routes';
import authSvc from '../../services/authentication';

import ProgressLoader from '../Progress';
import StepsDisplay from './StepsDisplay';
import Layout from './Layout';
import {
  numberOfEmployees,
  annualRevenues,
  totalMonthlyCardSpends,
  entityCategories,
  industries,
} from '../../constants/onboarding';

// fields
import FileDropzone from 'src/components/FileDropzone';
import FieldContainer from './Fields/FieldContainer';

import CustomTextField from '../CustomFormFields/CustomTextField';
import CustomSelectField from '../CustomFormFields/CustomSelectField';
import FormFieldContainer from '../CustomFormFields/FormFieldContainer';

// media
import image_director from '../../assets/onboarding/director.png';
import image_employee from '../../assets/onboarding/employee.png';
import image_freelancer from '../../assets/onboarding/freelancer.png';
import image_docIllustration from '../../assets/onboarding/docIllustration.png';

const fieldNames = {
  fieldName_position: 'account_type_id',
  fieldName_checkbox1: 'checkbox1',

  fieldName_registrationCountry: 'company_country_id',
  fieldName_solution: 'solution_id',

  fieldName_email: 'email',
  fieldName_gender: 'gender',
  fieldName_dateOfBirth: 'dateOfBirth',
  fieldName_countryOfBirth: 'countryOfBirth',
  fieldName_nationality: 'nationality',

  fieldName_businessLegalName: 'company_name',
  fieldName_entityCategory: 'entity_category',
  fieldName_entityType: 'entity_type',
  fieldName_businessNumber: 'bus_registration_no',
  fieldName_natureOfIndustry: 'industry_nature',
  fieldName_natureOfSubIndustry: 'sub_industry_nature',

  fieldName_businessActivity: 'businessActivity',
  fieldName_productDescription: 'productDescription',
  fieldName_companyWebsite: 'company_website',
  fieldName_numberOfEmployee: 'number_employees',
  fieldName_annualTurnover: 'annual_turnover',

  fieldName_addressUnit: 'floor_unit_no',
  fieldName_addressLine1: 'address_line_1',
  fieldName_addressLine2: 'address_line_2',
  fieldName_addressLine3: 'address_line_3',
  fieldName_postcode: 'postal_code',
  fieldName_addressCountry: 'country_id',

  fieldName_businessFiles: 'bus_document_attachment_id',

  fieldName_fileCategory: 'fileCategory',
  fieldName_checkbox2: 'checkbox2',
  fieldName_checkbox3: 'checkbox3',

  fieldName_created_by: 'created_by',
  // fieldName_: '',
};
const {
  fieldName_position,
  fieldName_checkbox1,
  fieldName_registrationCountry,
  fieldName_solution,
  fieldName_email,
  fieldName_gender,
  fieldName_dateOfBirth,
  fieldName_countryOfBirth,
  fieldName_nationality,
  fieldName_businessLegalName,
  fieldName_entityCategory,
  fieldName_entityType,
  fieldName_businessNumber,
  fieldName_natureOfIndustry,
  fieldName_natureOfSubIndustry,
  fieldName_businessActivity,
  fieldName_productDescription,
  fieldName_companyWebsite,
  fieldName_numberOfEmployee,
  fieldName_annualTurnover,
  fieldName_addressUnit,
  fieldName_addressLine1,
  fieldName_addressLine2,
  fieldName_addressLine3,
  fieldName_postcode,
  fieldName_addressCountry,
  fieldName_businessFiles,
  fieldName_fileCategory,
  fieldName_checkbox2,
  fieldName_checkbox3,
  fieldName_created_by,
} = fieldNames;

const validationSchemas = [
  Yup.object().shape({
    [fieldName_position]: Yup.string().required('Please select a type'),
    [fieldName_checkbox1]: Yup.string().required('Please check'),
  }),
  Yup.object().shape({
    [fieldName_businessLegalName]: Yup.string().required(
      'Please fill up this field',
    ),
    [fieldName_registrationCountry]: Yup.string().required(
      'Please fill up this field',
    ),
    [fieldName_entityCategory]: Yup.string().required(
      'Please fill up this field',
    ),
    [fieldName_entityType]: Yup.string().required('Please fill up this field'),
    [fieldName_businessNumber]: Yup.string().required(
      'Please fill up this field',
    ),
    [fieldName_natureOfIndustry]: Yup.string().required(
      'Please fill up this field',
    ),
    // [fieldName_natureOfSubIndustry]: Yup.string().required(
    //   'Please fill up this field',
    // ),
    [fieldName_productDescription]: Yup.string().required(
      'Please fill up this field',
    ),
    [fieldName_companyWebsite]: Yup.string(),
    [fieldName_numberOfEmployee]: Yup.string().required(
      'Please fill up this field',
    ),
    [fieldName_annualTurnover]: Yup.string().required(
      'Please fill up this field',
    ),
  }),
  Yup.object().shape({
    [fieldName_addressUnit]: Yup.string().required('Please fill up this field'),
    [fieldName_addressLine1]: Yup.string().required(
      'Please fill up this field',
    ),
    [fieldName_addressLine2]: Yup.string().required(
      'Please fill up this field',
    ),
    [fieldName_addressLine3]: Yup.string(),
    [fieldName_postcode]: Yup.string().required('Please fill up this field'),
    [fieldName_addressCountry]: Yup.string().required(
      'Please fill up this field',
    ),
  }),
  Yup.object().shape({
    [fieldName_businessFiles]: Yup.array().test({
      message: 'Please choose the required files',
      test: (arr) => arr.length > 0,
    }),
  }),
];

const positionOptions = [
  {
    label: 'I am a registered director of the company',
    value: 1,
    image: image_director,
  },
  {
    label: 'I am not a registered director or an employee',
    value: 2,
    image: image_employee,
  },
  {
    label: 'I am a freelancer',
    value: 3,
    image: image_freelancer,
  },
];

const setFormValueLocalStorage = (values) => {
  localStorage.setItem('onboarding-company', JSON.stringify(values));
};
const getFormValueLocalStorage = () => {
  return JSON.parse(localStorage.getItem('onboarding-company')) || {};
};
const removeFormValueLocalStorage = () => {
  return localStorage.removeItem('onboarding-company');
};

const useCustomCommonAPI = (api, props) => {
  const { respondKey, labelKey, valueKey } = props;

  const [value, setValue] = useState({
    options: [],
    rawData: [],
  });

  useEffect(() => {
    (async () => {
      const apiRes = await api();
      let options = [];
      if (apiRes && apiRes?.[respondKey]) {
        apiRes[respondKey].map((aData) => {
          options.push({
            label: aData[labelKey],
            value: aData[valueKey],
          });
        });
        setValue((prev) => {
          return {
            ...prev,
            options: options,
            rawData: apiRes[respondKey],
          };
        });
      }
    })();
  }, []);

  return {
    ...value,
  };
};

const Content = ({ stepTitle, contentTitle, hideHeader = false, children }) => {
  return (
    <Box display="flex" flexDirection="column" height="100%">
      {!hideHeader && (
        <>
          {/* Header */}
          <Box textAlign="center" py={3} px={2}>
            <Box>
              <Typography variant="caption">{stepTitle}</Typography>
            </Box>
            <Box>
              <Typography variant="h4" style={{ fontWeight: '700' }}>
                {contentTitle}
              </Typography>
            </Box>
          </Box>
          {stepTitle || contentTitle ? <Divider /> : null}
        </>
      )}
      {/* Content */}
      <Box flexGrow={1} py={2}>
        {children}
      </Box>
    </Box>
  );
};

const Form1 = (props) => {
  const theme = useTheme();
  return (
    <>
      <Field name={fieldName_position}>
        {({
          field, // { name, value, onChange, onBlur }
          form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
          meta,
        }) => {
          const { name, value, onChange } = field;
          const { touched, errors, setFieldValue } = form;
          let error = meta.touched && !!meta.error;

          return (
            <Box mb={2}>
              <FormFieldContainer
                error={error}
                helperText={meta.touched && meta.error ? meta.error : ''}
              >
                {positionOptions.map((aPosition, index) => {
                  let isActive = aPosition.value == value;
                  let activeProps = isActive
                    ? {
                        borderColor: theme.palette.primary.main,
                      }
                    : {
                        borderColor: theme.palette.grey['300'],
                      };
                  return (
                    <Box
                      key={index}
                      mb={2}
                      border={1}
                      p={2}
                      display="flex"
                      alignItems="center"
                      {...activeProps}
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => {
                        setFieldValue(name, aPosition.value);
                      }}
                    >
                      <Box pr={3}>
                        <img src={aPosition.image} height="60" width="60" />
                      </Box>
                      <Box flexGrow={1} fontWeight="600">
                        {aPosition.label}
                      </Box>
                    </Box>
                  );
                })}
              </FormFieldContainer>
            </Box>
          );
        }}
      </Field>
      <Field name={fieldName_checkbox1}>
        {({ field, form, meta }) => {
          const { value } = field;
          let error = meta.touched && !!meta.error;

          return (
            <FormFieldContainer
              error={error}
              helperText={meta.touched && meta.error ? meta.error : ''}
            >
              <FormControlLabel
                control={
                  <Checkbox {...field} checked={value} color="primary" />
                }
                label={
                  <Typography variant="body2">
                    I confirm that I will be the{' '}
                    <b>Administrator of the Business Account</b> and I am
                    authorised to fill out this form on behalf of the business.
                  </Typography>
                }
              />
            </FormFieldContainer>
          );
        }}
      </Field>
    </>
  );
};

const Form5 = (props) => {
  const { commonData = {} } = props;
  const {
    countries = [],
    entityCategories = [],
    entityTypes = [],
    industries = [],
    subIndustries = [],
    numberOfEmployees = [],
    annualRevenues = [],
  } = commonData;
  const { smBelow } = useBreakpoints();

  return (
    <Box maxWidth="100%" width={smBelow ? '100%' : '65%'}>
      <FieldContainer label="Business Legal Name" mb={2}>
        <CustomTextField
          name={fieldName_businessLegalName}
          placeholder="Enter company name"
        />
      </FieldContainer>
      <FieldContainer label="Business Registered Country" mb={2}>
        <CustomSelectField
          name={fieldName_registrationCountry}
          options={countries}
          placeholder="Select a country"
        />
      </FieldContainer>

      <FieldContainer label="Entity Category" mb={2}>
        <CustomSelectField
          name={fieldName_entityCategory}
          options={entityCategories}
          placeholder="Select business registration type"
        />
      </FieldContainer>
      <FieldContainer label="Entity Type" mb={2}>
        <CustomSelectField
          name={fieldName_entityType}
          options={entityTypes}
          placeholder="Select an entity type"
        />
      </FieldContainer>
      <FieldContainer label="Business Reg. number (UEN)" mb={2}>
        <CustomTextField
          name={fieldName_businessNumber}
          placeholder="Enter business Reg. number"
        />
      </FieldContainer>

      <FieldContainer label="Nature of Industry" mb={2}>
        <CustomSelectField
          name={fieldName_natureOfIndustry}
          options={industries}
          placeholder="Select any of following"
          // multiple={true}
        />
      </FieldContainer>
      <FieldContainer label="Nature of Sub-Industry" mb={2}>
        <CustomSelectField
          name={fieldName_natureOfSubIndustry}
          options={subIndustries}
          placeholder="Select any of following"
          // multiple={true}
        />
      </FieldContainer>
      {/* <FieldContainer label="Business Activity" mb={2}>
        <CustomTextField name={fieldName_businessActivity} />
      </FieldContainer> */}
      <FieldContainer label="Product/Service Description" mb={2}>
        <CustomTextField
          name={fieldName_productDescription}
          placeholder="e.g. We provide IT for companies."
          multiline
          rows={4}
        />
      </FieldContainer>
      <FieldContainer label="Company website (optional)" mb={2}>
        <CustomTextField
          name={fieldName_companyWebsite}
          placeholder="Enter website url"
        />
      </FieldContainer>

      <FieldContainer label="Number of Employee" mb={2}>
        <CustomSelectField
          name={fieldName_numberOfEmployee}
          options={numberOfEmployees}
          placeholder="Select..."
        />
      </FieldContainer>
      <FieldContainer label="Annual Turnover" mb={2}>
        <CustomSelectField
          name={fieldName_annualTurnover}
          options={annualRevenues}
          placeholder="Select..."
        />
      </FieldContainer>
    </Box>
  );
};

const Form7 = (props) => {
  const { commonData = {} } = props;
  const { countries = [] } = commonData;
  const { smBelow } = useBreakpoints();

  return (
    <Box maxWidth="100%" width={smBelow ? '100%' : '65%'}>
      <FieldContainer label="Floor/Unit No" mb={2}>
        <CustomTextField name={fieldName_addressUnit} />
      </FieldContainer>
      <FieldContainer label="Address Line 1" mb={2}>
        <CustomTextField name={fieldName_addressLine1} />
      </FieldContainer>
      <FieldContainer label="Address Line 2" mb={2}>
        <CustomTextField name={fieldName_addressLine2} />
      </FieldContainer>
      <FieldContainer label="Address Line 3 (Optional)" mb={2}>
        <CustomTextField name={fieldName_addressLine3} />
      </FieldContainer>
      <FieldContainer label="Postcode" mb={2}>
        <CustomTextField name={fieldName_postcode} />
      </FieldContainer>
      <FieldContainer label="Country" mb={2}>
        <CustomSelectField
          name={fieldName_addressCountry}
          options={countries}
          placeholder="Select a country"
        />
      </FieldContainer>
    </Box>
  );
};

const Form8 = (props) => {
  return (
    <>
      <Box
        border={2}
        borderRadius={5}
        style={{ backgroundColor: yellow['100'], borderColor: yellow['500'] }}
        mb={3}
        p={3}
      >
        <Box fontWeight="bold">
          Please provide all the following documents for verification
        </Box>
        <Box>
          <ul>
            <li>
              Passport or National ID of other directors/partners listed in ACRA
              Bizfile
            </li>
            <li>
              Passport or National ID of shareholders with at least 10% shares
            </li>
            <li>Constitution, or Articles of Association</li>
            <li>
              Signed board resolution letter authorizing you to open a bank
              ccount for your company.
            </li>
          </ul>
        </Box>
      </Box>
      <Box>
        <Box>Upload applicable documents</Box>
        <Box>
          <Typography variant="caption">
            You can upload multiple files in one go. If you miss anything, we
            might ask for it later.
          </Typography>
        </Box>
        <Field name={fieldName_businessFiles}>
          {({ field, form, meta }) => {
            const { name, value } = field;
            const { setFieldValue } = form;

            let error = meta.touched && !!meta.error;

            return (
              <FormFieldContainer
                error={error}
                mb={2}
                helperText={
                  <span>{meta.touched && meta.error ? meta.error : ''}</span>
                }
              >
                <Box>
                  <FileDropzone
                    {...field}
                    {...props}
                    multiple={true}
                    files={value || []}
                    setFiles={(files = []) => {
                      setFieldValue(name, files);
                    }}
                  />
                </Box>
              </FormFieldContainer>
            );
          }}
        </Field>
      </Box>
    </>
  );
};

const Form13 = (props) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <Box p={5} mb={3}>
        <img src={image_docIllustration} width="100" />
      </Box>
      <Box textAlign="center">
        <Box mb={1}>
          <Typography variant="h4">
            Thanks! We’ll review your documents withing 24 hours
          </Typography>
        </Box>
        <Typography variant="caption">
          We’ll send you the email notification.
        </Typography>
      </Box>
    </Box>
  );
};

const steps = [
  {
    id: 1,
    label: 'Business Representative',
  },
  {
    id: 2,
    label: 'Business Structure',
  },
  {
    id: 3,
    label: 'Business Details',
  },
  {
    id: 4,
    label: 'Business Documents',
  },
  {
    id: 5,
    label: 'Confirmation',
  },
];

const forms = [
  {
    id: 1,
    stepTitle: 'Business Representative',
    title: 'I want to open new Meyzer360 account',
    component: Form1,
    validationSchema: validationSchemas[0],
  },
  {
    id: 2,
    stepTitle: 'Business Structure',
    title: 'Fill in your business information',
    component: Form5,
    validationSchema: validationSchemas[1],
  },
  {
    id: 3,
    stepTitle: 'Business Details',
    title: 'Fill in your business address',
    component: Form7,
    validationSchema: validationSchemas[2],
  },
  {
    id: 4,
    stepTitle: 'Business Documents',
    title: 'Submit your business documents',
    component: Form8,
    validationSchema: validationSchemas[3],
  },
  {
    id: 5,
    stepTitle: 'Confirmation',
    title: 'Document Verification',
    component: Form13,
  },
];

const OnboardingCompany = () => {
  const defaultValues = getFormValueLocalStorage();
  const { formPage: defaultFormPage, ...restDefaultValues } = defaultValues;
  const { smBelow } = useBreakpoints();
  const routeHistory = useHistory();

  // const [activeStep, setActiveStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [formPage, setFormPage] = useState(
    defaultFormPage ? defaultFormPage : 0,
  );
  let activeStep = formPage;
  // if (formPage <= 4) {
  //   activeStep = 1;
  // } else if (formPage <= 8) {
  //   activeStep = 2;
  // } else if (formPage <= 13) {
  //   activeStep = 3;
  // }

  const foundForm = forms[formPage];

  const { options: countryOptions = [] } = useCustomCommonAPI(
    commonSvc.countries,
    {
      labelKey: 'country_name',
      valueKey: 'country_id',
      respondKey: 'countries',
    },
  );

  const { options: genderOptions = [] } = useCustomCommonAPI(
    commonSvc.genders,
    {
      labelKey: 'gender_name',
      valueKey: 'gender_code',
      respondKey: 'genders',
    },
  );
  const { options: applicationTypeOptions = [] } = useCustomCommonAPI(
    commonSvc.companyApplicationTypes,
    {
      labelKey: 'application_type_table_name',
      valueKey: 'company_application_type_id',
      respondKey: 'applicationtype',
    },
  );
  const { options: companyTypeOptions = [] } = useCustomCommonAPI(
    commonSvc.companyTypes,
    {
      labelKey: 'company_type_name',
      valueKey: 'company_type_id',
      respondKey: 'company_types',
    },
  );

  const entityCategoriesOptions = entityCategories.map((anItem) => {
    return {
      label: anItem.label,
      value: anItem.label,
    };
  });

  const industriesOptions = industries.map((anItem) => {
    return {
      label: anItem.label,
      value: anItem.label,
    };
  });

  const numberOfEmployeeOptions = numberOfEmployees.map((anItem) => {
    return {
      label: anItem.label,
      value: anItem.label,
    };
  });
  const annualRevenueOptions = annualRevenues.map((anItem) => {
    return {
      label: anItem.label,
      value: anItem.label,
    };
  });

  let formObj = forms?.[formPage] || {};
  const { validationSchema = {} } = formObj;
  const CurrentForm = formObj?.component;

  const handleSubmit = async (values, canSubmit = false) => {
    if (canSubmit) {
      const {
        [fieldName_businessFiles]: businessFiles,
        ...restValues
      } = values;
      let newPageIndex = formPage + 1;
      if (newPageIndex < forms.length - 1) {
        setFormValueLocalStorage({
          ...restValues,
          formPage: newPageIndex,
        });
        setFormPage((prev) => newPageIndex);
      } else {
        const {
          [fieldName_checkbox1]: checkValue,
          [fieldName_businessFiles]: selectedFiles = [],
          ...restValues
        } = values;
        setLoading(true);
        const auth = authSvc.getAuthData();
        if (selectedFiles && selectedFiles.length > 0 && auth?.user?.user_id) {
          // submit
          try {
            let createProfileResult = await companySvc.onBoardProfile({
              ...restValues,
              [fieldName_created_by]: auth?.user?.user_id,
            });
            console.log('createProfileResult', createProfileResult);
            if (createProfileResult?.data?.is_success) {
              let profileId =
                createProfileResult?.data?.onboard_profile?.onboard_profile_id;
              await S3UploadAndInsertDocuments(
                selectedFiles[0],
                135,
                11,
                profileId,
              );
              // for (let i = 0; i < selectedFiles.length; i++) {
              // }
            }

            setFormPage((prev) => newPageIndex);
            // removeFormValueLocalStorage();
          } catch (error) {}

          setLoading(false);
        }

        console.log('Formik values2: ', values);
      }
    }
  };

  return (
    <Layout
      subHeader={
        smBelow ? (
          <Box display="flex" justifyContent="center">
            <StepsDisplay
              activeStep={activeStep}
              steps={steps}
              formPage={formPage}
              horizontal={true}
            />
          </Box>
        ) : null
      }
    >
      <Container maxWidth="lg" style={{ display: 'flex' }}>
        <Grid container spacing={2}>
          {smBelow ? null : (
            <Grid item xs={3} sm={3} md={3}>
              <Box height="100%">
                <StepsDisplay
                  activeStep={activeStep}
                  steps={steps}
                  formPage={formPage}
                />
              </Box>
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={6}>
            <Formik
              initialValues={restDefaultValues}
              validationSchema={validationSchema}
              onSubmit={() => {}}
            >
              {(props) => {
                const { values, validateForm, setTouched, touched } = props;

                const selectedEntityCategory = values[fieldName_entityCategory];
                let foundEntityCategory = entityCategories.find(
                  (aCategory) => aCategory.label == selectedEntityCategory,
                );
                let entityTypeOptions = [];
                if (
                  foundEntityCategory &&
                  foundEntityCategory.children &&
                  foundEntityCategory.children.length > 0
                ) {
                  entityTypeOptions = foundEntityCategory.children.map(
                    (anItem) => {
                      return {
                        label: anItem.label,
                        value: anItem.label,
                      };
                    },
                  );
                }

                const selectedIndustry = values[fieldName_natureOfIndustry];
                let foundIndustry = industries.find(
                  (aCategory) => aCategory.label == selectedIndustry,
                );

                let subIndustriesOptions = [];
                if (
                  foundIndustry &&
                  foundIndustry.children &&
                  foundIndustry.children.length > 0
                ) {
                  subIndustriesOptions = foundIndustry.children.map(
                    (anItem) => {
                      return {
                        label: anItem.label,
                        value: anItem.label,
                      };
                    },
                  );
                }

                return (
                  <Form style={{ height: '100%' }}>
                    <Paper display="flex" style={{ height: '100%' }}>
                      <Box display="flex" flexDirection="column" height="100%">
                        <Box flexGrow={1}>
                          <Container maxWidth="xs" style={{ height: '100%' }}>
                            <Content
                              stepTitle={foundForm?.stepTitle}
                              contentTitle={foundForm?.title}
                            >
                              {CurrentForm && (
                                <CurrentForm
                                  commonData={{
                                    countries: countryOptions,
                                    genders: genderOptions,
                                    applicationTypes: applicationTypeOptions,
                                    companyTypes: companyTypeOptions,
                                    entityCategories: entityCategoriesOptions,
                                    entityTypes: entityTypeOptions,
                                    industries: industriesOptions,
                                    subIndustries: subIndustriesOptions,
                                    numberOfEmployees: numberOfEmployeeOptions,
                                    annualRevenues: annualRevenueOptions,
                                  }}
                                  values={values}
                                />
                              )}
                            </Content>
                          </Container>
                        </Box>
                        <Divider />
                        {/* Footer */}
                        <Box
                          py={3}
                          px={2}
                          display="flex"
                          justifyContent="space-between"
                        >
                          {formPage > 0 && formPage < forms.length - 1 && (
                            <Button
                              variant="contained"
                              disableElevation={true}
                              style={{ width: '150px' }}
                              onClick={() => {
                                let newPageIndex = formPage - 1;
                                if (newPageIndex >= 0) {
                                  setFormPage((prev) => newPageIndex);
                                  setFormValueLocalStorage({
                                    ...defaultValues,
                                    formPage: newPageIndex,
                                  });
                                }
                              }}
                            >
                              Back
                            </Button>
                          )}
                          {formPage < forms.length && (
                            <Button
                              variant="contained"
                              disableElevation={true}
                              color="primary"
                              style={{ width: '150px', marginLeft: 'auto' }}
                              // type="submit"
                              type="button"
                              onClick={() => {
                                // console.log("touched", touched);
                                // setTouched(true, true);
                                if (formPage < forms.length - 1) {
                                  validateForm()
                                    .then((result) => {
                                      console.log(
                                        'validateForm result',
                                        result,
                                      );
                                      setTouched({
                                        ...touched,
                                        ...result,
                                      });
                                      let canSubmit = false;
                                      if (
                                        result &&
                                        Object.keys(result).length == 0
                                      ) {
                                        canSubmit = true;
                                      }
                                      if (canSubmit) {
                                        handleSubmit(values, canSubmit);
                                      }
                                    })
                                    .catch((errors) => {
                                      console.log('validateForm error', errors);
                                      setTouched({ ...touched, ...errors });
                                    });
                                } else {
                                  // redirect
                                  removeFormValueLocalStorage();
                                  routeHistory.push(
                                    ROUTES.CORPSEC_COMPANY_ACCOUNT_SETTINGS,
                                  );
                                }
                              }}
                              // onClick={() => {
                              //   const {
                              //     [fieldName_businessFiles]: businessFiles,
                              //     ...restValues
                              //   } = values;

                              //   let newPageIndex = formPage + 1;
                              //   if (newPageIndex <= forms.length) {
                              //     // setFormValueLocalStorage({
                              //     //   ...restValues,
                              //     //   formPage: newPageIndex,
                              //     // });
                              //     // setFormPage((prev) => newPageIndex);
                              //     let x = setTouched({})
                              //       .then((result) => {
                              //         console.log('setTouched result: ', result);
                              //       })
                              //       .catch((error) => {
                              //         console.log('setTouched error: ', error);
                              //       });

                              //     console.log('Formik values1: ', x);
                              //   } else {
                              //     console.log('Formik values2: ', values);
                              //   }
                              // }}
                            >
                              Next
                            </Button>
                          )}
                        </Box>
                      </Box>
                    </Paper>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
          <Grid item sm={3} md={3} />
        </Grid>
      </Container>
    </Layout>
  );
};

export default OnboardingCompany;
