import { useContext, useState, createContext } from 'react';
import { Config } from 'src/config';
import { APIRequest } from 'src/utils/api';
import companySvc from 'src/services/company';
import { ConfigContext } from 'src/contexts';

const timelineContext = createContext({
  error: '',
  tlLoading: false,
  submitting: false,
  ListTimeline: () => Promise.resolve({}),
});

export function useProvideTimeline() {
  const [error, setError] = useState();
  const [tlLoading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [timelineList, setTimelineList] = useState([]);
  const { company } = useContext(ConfigContext);
  const ListTimeline = async () => {
    // const company = companySvc.getCompany();
    console.log('companytimeline', company);
    const res = await APIRequest({
      setError: setError,
      setLoading: setLoading,
      // path: `${Config.apiURL}/company/logtimeline/company/1`,
      path: `${Config.apiURL}/company/logtimeline/company/${company?.company_id}`,
      method: 'get',
    });
    if (res.status === 'success') {
      setTimelineList(res.body.log_timeline);
    }
    return res;
  };

  return {
    error,
    tlLoading,
    submitting,
    timelineList,
    ListTimeline,
  };
}

export const useCartContext = () => useContext(timelineContext);

export function ProvideTimeline({ children }) {
  const timeline = useProvideTimeline();
  return (
    <timelineContext.Provider value={timeline}>
      {children}
    </timelineContext.Provider>
  );
}
