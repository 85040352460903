import React, { useState, useContext, useEffect } from 'react';
import {
  Typography,
  Box,
  ButtonBase,
  Paper,
  Grid,
  makeStyles,
  Divider,
  Tabs,
  Tab,
  InputAdornment,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';
import ROUTES from 'src/constants/routes';
import useButtonStyle from 'src/styles/button';
import MzButton from 'src/components/MzButton';
import { Formik, Form } from 'formik';
import FormTextField from 'src/components/FormTextField/FormTextField.js';
import * as Yup from 'yup';
import useBreakpoints from 'src/hooks/useBreakpoints';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import AlertBox from 'src/components/AlertBox';
import fourSteps from 'src/assets/requests/4steps.png';
import Stepper from 'src/components/Stepper';
import FormDropdown from 'src/components/FormDropdown/FormDropdown.js';
import { FormFileDropzone } from 'src/components/Form';
import DetailLabel from 'src/components/DetailLabel';
import FormDropdownTextField from 'src/components/FormDropdownTextField/FormDropdownTextField.js';
import GetStartedLayout from 'src/components/Corpsec/Requests/GetStartedLayout';
import { useProvideShareholder } from 'src/hooks/useShareholders';
import { useProvideRequest } from 'src/hooks/useRequests';
import { useProvideProduct } from 'src/hooks/useProduct';
import companySvc from 'src/services/company';
import { ConfigContext } from 'src/contexts';
import useSnackbar from 'src/hooks/useSnackbar';
import { S3UploadAndInsertDocuments } from 'src/utils/aws';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import TodayIcon from '@material-ui/icons/Today';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { EnhancedIcon } from 'src/components/Enhanced';
import EnhancedTextField from 'src/components/EnhancedTextField/EnhancedTextField';
import DottedLabel from 'src/components/DottedLabel';
import RowTable from 'src/components/RowTable';

const tempSteps = [
  {
    id: 1,
    label: 'Share Capital',
  },
  {
    id: 2,
    label: 'Allotment',
  },
];

const currencyList = [
  { id: 1, label: 'EUR' },
  { id: 2, label: 'GBP' },
  { id: 3, label: 'JPY' },
  { id: 4, label: 'SGD' },
  { id: 5, label: 'USD' },
];

const shareholderList = [
  { label: 'Gideon' },
  { label: 'Edison' },
  { label: 'Tan' },
];

const useStyles = makeStyles((theme) => ({
  fontWeight: {
    fontWeight: 600,
  },
}));

const AllotmentShares = ({ toggleShowMenu }) => {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const { smBelow } = useBreakpoints();
  const buttonClasses = useButtonStyle();
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [countryCode, setCountryCode] = useState('SG');
  const [proofOfCapital, setProofOfCapital] = useState([]);
  const [sharesNumber, setSharesNumber] = useState(0);
  const [paidupCapital, setPaidupCapital] = useState(0);
  const [allotmentNewShare, setAllotmentNewShare] = useState('');
  const [allotmentNewCapital, setAllotmentNewCapital] = useState('');
  const [allotmentShareholderID, setAllotmentShareholderID] = useState();
  const [currency, setCurrency] = useState(currencyList[3].label);
  const {
    addCart,
    user,
    cartSubmitting,
    setConfirmationDialog,
    company,
  } = useContext(ConfigContext);
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { ReadProduct, product, productError } = useProvideProduct();
  const {
    GetShareholderByUserID,
    shareholderError,
    shareholder,
    shareholders,
    newshareholder,
    ListShareholders,
    ApplyNewShareholder,
  } = useProvideShareholder();
  const {
    CreateShareAllotmentApplication,
    requestSubmitting,
    requestError,
  } = useProvideRequest();

  const step2validationSchema = Yup.object({
    currency: Yup.string().required('Currency is required'),
    ordinaryshares: Yup.string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .required('Ordinary Shares is required'),
    amount: Yup.string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .required('Amount is required'),
    capitalFiles: Yup.array().min(1),
  });

  const step3validationSchema = Yup.object({
    allotment_shareholder_id: Yup.string().when('newshareholder', {
      is: () => !newshareholder,
      then: Yup.string().required('Shareholder is required'),
    }),
    newshares: Yup.string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .required('New Shares is required'),
    newcapital: Yup.string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .required('New Capital is required'),
  });

  const newShareholderValidationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    nric_passport_no: Yup.string().required('ID is required'),
    mobile_number: Yup.string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .required('Mobile Number is required'),
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
    identityFiles: Yup.array().when('tabValue', {
      is: () => tabValue === 0,
      then: Yup.array().min(1),
    }),
    residencyFiles: Yup.array().when('tabValue', {
      is: () => tabValue === 2,
      then: Yup.array().min(1),
    }),
    passportFiles: Yup.array().when('tabValue', {
      is: () => tabValue === 2,
      then: Yup.array().min(1),
    }),
  });

  const getStarted = {
    one: {
      content: [
        'Issue new Ordinary Shares fully payable in Cash for SGD, USD, GBP, EUR or JPY',
        <Typography
          key={''}
          align="justify"
          variant="body2"
          color="textPrimary"
          display="inline"
        >
          {'Allotment of new shares to existing or new'}
          {/* <Typography
            align="justify"
            variant="body2"
            color="textPrimary"
            display="inline"
            className={classes.fontWeight}
          > */}
          {' Individual '}
          {/* </Typography> */}
          {'shareholder'}
        </Typography>,
        'For issue of other share type and currency, please submit under Other Request',
      ],
    },
    two: {
      content: [
        'Number of Ordinary Share to issue & Paid-up Capital',
        'Copy of Bank Statement or Bank Receipt (jpg, png, pdf format)',
        'Shareholder particulars & copy of NRIC/ Passport (For New Shareholder)',
        'Allotment Details (Number of New Shares & Capital for each shareholder)',
      ],
    },
    three: {
      content: [
        'Create Request',
        'Meyzer360 Review',
        'Sign Resolution',
        'ACRA Filing',
      ],
    },
    four: {
      content: ['Basic Plan: SGD100', 'Unlimited Plan: SGD100'],
    },
    five: {
      content: [
        'Time to fill form: < 5 minutes.',
        'Average processing time: 10-15 days',
      ],
    },
  };

  useEffect(() => {
    // get latest product
    ReadProduct(20);
    GetShareholderByUserID(user.user_id);
    ListShareholders(company.company_id);
  }, []);

  useEffect(() => {
    if (requestError) {
      showErrorSnackbar(`Error submitting application. ${requestError}`);
    }
  }, [requestError]);

  const getShareholderByCompany = (shareholders = []) => {
    return shareholders.find((sh) => sh.company_id === company.company_id);
  };

  const setShareholderOptions = (shareholders = []) => {
    return shareholders.map((sh) => ({
      label: sh.Name,
      value: sh.user_id,
    }));
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        style={{ width: '100%' }}
        hidden={value !== index}
        {...other}
      >
        {value === index && <>{children}</>}
      </div>
    );
  };

  const formikOnSubmitStep2 = async (values, actions) => {
    setSharesNumber(values.ordinaryshares);
    setPaidupCapital(values.amount);
    setCurrency(values.currency);
    setProofOfCapital(values.capitalFiles);
    setStep(3);
  };

  const formikOnSubmitNewShareholder = async (values, actions) => {
    setLoading(true);
    if (values.identityFiles.length > 0) {
      const identityFilesID = await S3UploadAndInsertDocuments(
        values.identityFiles[0],
        76,
        6,
        'officer_application_id',
      );
      values.proof_identity_attachment_id = identityFilesID.document_id;
    }

    if (values.residencyFiles.length > 0) {
      const residencyFilesID = await S3UploadAndInsertDocuments(
        values.residencyFiles[0],
        112,
        6,
        'officer_application_id',
      );
      values.proof_residency_attachment_id = residencyFilesID.document_id;
    }

    if (values.passportFiles.length > 0) {
      const passportFilesID = await S3UploadAndInsertDocuments(
        values.passportFiles[0],
        77,
        6,
        'officer_application_id',
      );
      values.passport_attachment_id = passportFilesID.document_id;
    }

    let res = await ApplyNewShareholder({
      company_id: company.company_id,
      company_application_status_id: 11,
      is_foreigner: tabValue == 0 ? 0 : 1,
      shareholder_name: values.name,
      identity_id: values.nric_passport_no,
      mobile_number: values.mobile_number,
      email: values.email,
      proof_identity_attachment_id: values.proof_identity_attachment_id,
      passport_attachment_id: values.passport_attachment_id,
      proof_residency_attachment_id: values.proof_residency_attachment_id,
      created_by: user.user_id,
    });
    setLoading(false);
    if (res.status === 'success') {
      actions.resetForm();
      setStep(3);
    }
  };

  const formikOnSubmit = async (values, actions) => {
    setSubmitting(true);
    values.company_id = company.company_id;
    values.company_application_status_id = 11;
    values.created_by = user.user_id;
    values.transfer_from_shareholder_id = getShareholderByCompany(
      shareholder,
    )?.shareholder_id;
    values.no_shares = sharesNumber;
    values.issued_paid_up_amount = paidupCapital;
    values.currency = currency;
    values.is_new_shareholder = 0;
    values.new_shares = allotmentNewShare;
    values.new_capital = allotmentNewCapital;
    values.allotment_shareholder_id = allotmentShareholderID;
    if (newshareholder) {
      values.is_new_shareholder = 1;
      values.allotment_shareholder_id = null;
      values = { ...values, ...newshareholder };
    }

    try {
      if (proofOfCapital.length > 0) {
        const capitalFile = await S3UploadAndInsertDocuments(
          proofOfCapital[0],
          110,
          4,
          'customer_due_diligence_id',
        );
        values.proof_capital_attachment_id = capitalFile.document_id;
      }
    } catch (error) {
      showErrorSnackbar(`Error uploading document`);
      return;
    }
    let res;
    try {
      res = await CreateShareAllotmentApplication(values);
    } catch (error) {
      showErrorSnackbar('Error submitting application');
    }
    if (res?.status === 'success') {
      try {
        res = await addCart({
          price: product.price,
          product_id: product.id,
          quantity: 1,
          user_id: user.user_id,
          product_detail_id:
            res.body.applications.shares_allotment_application_id,
          company_id: company.company_id,
          company_name: company.company_name,
          company_registration_no: company.company_registration_no,
          company_application_id: res.body.applications.company_application_id,
        });
        setSubmitting(false);
        if (res.status === 'success') {
          actions.resetForm();
          setConfirmationDialog({
            isVisible: true,
            title: 'Item added to cart',
            type: 'success',
            cancelButtonLabel: 'Back to Request',
            confirmButtonLabel: 'View your cart',
            confirmButtonAction: () => {
              history.push(ROUTES.PAYMENTCART);
              setConfirmationDialog({
                isVisible: false,
              });
            },
            cancelButtonAction: () => {
              history.push(ROUTES.CORPSEC_REQ);
              setConfirmationDialog({
                isVisible: false,
              });
            },
          });
        } else {
          showErrorSnackbar('Error adding to cart');
        }
      } catch (error) {
        showErrorSnackbar('Error adding to cart');
      }
    } else {
      showErrorSnackbar('Error submitting application');
    }
  };

  const stepTwo = () => {
    return (
      <Formik
        initialValues={{
          currency: currencyList[3].label,
          ordinaryshares: '',
          amount: '',
          capitalFiles: '',
        }}
        onSubmit={formikOnSubmitStep2}
        validationSchema={step2validationSchema}
      >
        {(props) => (
          <Form style={{ width: '100%' }}>
            <FormDropdown
              autoCompleteStyle={{ width: smBelow ? '100%' : '50%' }}
              name="currency"
              onChange={(event, newValue) => {
                props.setFieldValue('currency', newValue?.label);
              }}
              optionList={currencyList}
              labelText="Select Currency"
              placeholder="Select One"
              fullWidth
              containerWidth="50%"
              shrink
              variant="outlined"
              defaultValue={currencyList[3]}
            />
            <FormTextField
              name="ordinaryshares"
              labelText="No. of Ordinary Shares *"
              fullWidth={smBelow ? true : false}
              variant="outlined"
            />
            <FormTextField
              name="amount"
              labelText="Amount of Issued/ Paid Up Share Capital *"
              fullWidth={smBelow ? true : false}
              shrink
              variant="outlined"
            />
            <FormFileDropzone
              containerWidth={smBelow ? '100%' : '50%'}
              name="capitalFiles"
              labelText="Proof of Capital (Bank of Statement or Bank Receipt) *"
            />
            <Box display="flex" pt={3} alignItems="flex-start">
              <MzButton
                // disabled={!(props.isValid && props.dirty)}
                type="submit"
                onClick={() => {}}
                title={'Save & Proceed'}
              />
            </Box>
          </Form>
        )}
      </Formik>
    );
  };

  const stepThree = () => {
    return (
      <Formik
        initialValues={{
          allotment_shareholder_id: '',
          newshares: '',
          newcapital: '',
        }}
        onSubmit={formikOnSubmit}
        validationSchema={step3validationSchema}
      >
        {(props) => (
          <Form style={{ width: '100%' }}>
            <DottedLabel label="Currency:" value={currency} />
            <>
              <Grid container>
                <Paper
                  variant="outlined"
                  square
                  style={{
                    width: '100%',
                    padding: '10px',
                  }}
                >
                  <Grid container item xs={12} spacing={3}>
                    <Grid item xs={6} />
                    <Grid item xs={3}>
                      <Typography variant="h5">New</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="h5">Balance</Typography>
                    </Grid>
                  </Grid>
                </Paper>
                <RowTable
                  label="No. of Shares"
                  latest={sharesNumber}
                  balance={sharesNumber - allotmentNewShare}
                />
                <RowTable
                  label="Issued/ Paid-up Capital"
                  latest={paidupCapital}
                  balance={paidupCapital - allotmentNewCapital}
                />
              </Grid>
            </>
            <Box py={3} />
            <Box
              p={2}
              display="flex"
              flexDirection={smBelow ? 'column' : 'row'}
              justifyContent="space-between"
              alignItems={smBelow ? 'flex-start' : 'center'}
              width="100%"
            >
              <DottedLabel value="Allotment Details" />
              <ButtonBase
                style={{ justifyContent: 'flex-start', color: '#A21D23' }}
                onClick={() => {
                  setStep(4);
                }}
              >
                <Typography variant="h6">Add New Shareholder</Typography>
                <ChevronRightIcon />
              </ButtonBase>
            </Box>
            <Paper
              style={{
                width: '100%',
                borderRadius: 10,
              }}
            >
              <Box p={3}>
                {newshareholder && (
                  <>
                    <DetailLabel
                      label="New Shareholder"
                      value={newshareholder.shareholder_name}
                    />
                    <Box p={1} />
                  </>
                )}
                {!newshareholder && (
                  <FormDropdown
                    autoCompleteStyle={{ width: smBelow ? '100%' : '50%' }}
                    name="allotment_shareholder_id"
                    onChange={(event, newValue) => {
                      setAllotmentShareholderID(newValue.value);
                      props.setFieldValue(
                        'allotment_shareholder_id',
                        newValue?.value,
                      );
                    }}
                    optionList={setShareholderOptions(shareholders)}
                    labelText="SELECT SHAREHOLDER"
                    placeholder="Select One"
                    fullWidth
                    containerWidth="50%"
                    shrink
                    variant="outlined"
                  />
                )}

                <Box p={1} />
                <Box display="flex" flexDirection={smBelow ? 'column' : 'row'}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    width={props.fullWidth ? '100%' : '50%'}
                  >
                    <EnhancedTextField
                      disabled
                      labelText="EXISTING NO. OF SHARES"
                      value="1000"
                      color="primary"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      width={smBelow ? '100%' : '33%'}
                    />
                  </Box>
                  <FormTextField
                    name="newshares"
                    labelText="NEW SHARE"
                    variant="outlined"
                    onChange={(event) => {
                      props.setFieldValue('newshares', event.target.value);
                      setAllotmentNewShare(event.target.value);
                    }}
                    width={smBelow ? '100%' : '33%'}
                  />
                  {!smBelow && <Box p={1} />}
                  <FormTextField
                    name="newcapital"
                    labelText="NEW CAPITAL"
                    variant="outlined"
                    onChange={(event) => {
                      props.setFieldValue('newcapital', event.target.value);
                      setAllotmentNewCapital(event.target.value);
                    }}
                    width={smBelow ? '100%' : '33%'}
                  />
                </Box>
              </Box>
            </Paper>
            <Box p={2} />
            <Box display="flex" alignItems="flex-start">
              <MzButton
                // disabled={!(props.isValid && props.dirty)}
                type="submit"
                onClick={() => {}}
                title={'Submit'}
                loading={cartSubmitting || requestSubmitting}
              />
            </Box>
          </Form>
        )}
      </Formik>
    );
  };

  const addNewShareholder = () => {
    return (
      <>
        <Typography variant="h6" align="left" className={classes.fontWeight}>
          Fill in information for New Shareholder
        </Typography>
        <Box p={1} />
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          style={{
            borderBottom: '0.01rem solid #E4E4E4',
            paddingLeft: theme.spacing(smBelow ? 3 : 12),
          }}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab
            label="Local"
            style={{
              textTransform: 'initial',
              fontSize: 16,
            }}
          />
          <Tab
            label="Foreigner"
            style={{
              textTransform: 'initial',
              fontSize: 16,
            }}
          />
        </Tabs>
        <Box p={1} />
        <TabPanel value={tabValue} index={0}>
          {renderForm()}
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          {renderForm()}
        </TabPanel>
      </>
    );
  };

  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          company_role_id: 5,
          name: '',
          nric_passport_no: '',
          email: '',
          mobile_number: '',
          identityFiles: [],
          residencyFiles: [],
          passportFiles: [],
        }}
        onSubmit={formikOnSubmitNewShareholder}
        validationSchema={newShareholderValidationSchema}
      >
        {(props) => (
          <Box p={3} pl={smBelow ? 3 : 12}>
            <Form>
              <FormTextField
                fullWidth={smBelow ? true : false}
                name="name"
                labelText={
                  tabValue === 0
                    ? 'NAME (as in NRIC/FIN) *'
                    : 'NAME (as in PASSPORT) *'
                }
                placeholder="Name"
                shrink
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonOutlineIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <FormTextField
                fullWidth={smBelow ? true : false}
                name="nric_passport_no"
                labelText="ID *"
                placeholder="ID"
                shrink
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CreditCardIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <FormDropdownTextField
                name="mobile_number"
                labelText="Mobile Number *"
                placeholder="91234567"
                containerWidth={smBelow ? '100%' : '50%'}
                fullWidth
                shrink
                variant="outlined"
                showFlag={false}
                onValueChange={(event, newValue) => {
                  setCountryCode(newValue.code);
                }}
                countryCode={countryCode}
              />
              <FormTextField
                fullWidth={smBelow ? true : false}
                name="email"
                labelText="Email Address *"
                placeholder="Email Address"
                shrink
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailOutlineIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {tabValue === 0 && (
                <FormFileDropzone
                  containerWidth={smBelow ? '100%' : '50%'}
                  name="identityFiles"
                  labelText="Proof of Identity *"
                />
              )}

              {tabValue === 1 && (
                <Box
                  display="flex"
                  flexDirection={smBelow ? 'column' : 'row'}
                  style={{
                    gap: '20px',
                  }}
                >
                  <FormFileDropzone
                    containerWidth={smBelow ? '100%' : '50%'}
                    name="passportFiles"
                    labelText="Copy of Passport *"
                  />
                  <FormFileDropzone
                    containerWidth={smBelow ? '100%' : '50%'}
                    name="residencyFiles"
                    labelText="Proof of Residency (Must be in English) *"
                  />
                </Box>
              )}

              <Box display="flex" pt={3} justifyContent="flex-start">
                <MzButton
                  // disabled={!(props.isValid && props.dirty)}
                  fullWidth={smBelow ? true : false}
                  type="submit"
                  title={'SAVE'}
                  onClick={() => {}}
                  loading={loading}
                />
              </Box>
            </Form>
          </Box>
        )}
      </Formik>
    );
  };

  return (
    <>
      <GetStartedLayout
        toggleShowMenu={toggleShowMenu}
        step={step}
        setStep={setStep}
        getStarted={getStarted}
        header={
          <>
            <Typography variant="h2">
              {step === 1 && 'Allotment of Shares'}
              {(step === 2 || step === 3 || step === 4) && 'Allotment Details'}
            </Typography>
            <BreadcrumbsNav
              pathList={[
                { path: 'Requests', route: ROUTES.CORPSEC_REQ },
                {
                  path: 'Change Share Information',
                  route: ROUTES.CORPSEC_REQ_CHANGE_SHARE_INFORMATION,
                },
                {
                  path: 'Allotment of Shares',
                  color: 'primary',
                },
              ]}
            />
          </>
        }
      >
        {step !== 1 && (
          <Box px={4} py={4} style={{ borderTop: '0.01rem solid #E4E4E4' }}>
            {(step === 2 || step === 3) && (
              <Stepper
                steps={tempSteps}
                providedStep={step - 2}
                setProvidedStep={(step) => {
                  setStep(step + 2);
                }}
              />
            )}
            {step === 2 && stepTwo()}
            {step === 3 && stepThree()}
            {step === 4 && addNewShareholder()}
          </Box>
        )}
        <Box py={5} />
      </GetStartedLayout>
    </>
  );
};

export default AllotmentShares;
