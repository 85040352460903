import React from 'react';
import {
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Typography,
  Box,
  Divider,
} from '@material-ui/core';
import { Field } from 'formik';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({}));

function FormRadioGroupDetails({ name, label, options = [], ...props }) {
  return (
    <Field name={name}>
      {({ field, meta }) => {
        return (
          <>
            <FormControl error={meta.touched && !!meta.error}>
              <RadioGroup {...props}>
                {options.map((opt, index) => (
                  <>
                    <Box p={2} pl={5} display="flex" alignItems="center">
                      <FormControlLabel
                        key={opt.value}
                        value={opt.value}
                        control={<Radio color="primary" />}
                        label={opt.label}
                      />
                      <Box p={2} />
                      <Typography variant="body1">{opt.desc}</Typography>
                    </Box>
                    {options.length - 1 !== index && <Divider />}
                  </>
                ))}
              </RadioGroup>
              {meta.touched && !!meta.error && (
                <Box pb={2} pl={6}>
                  <FormHelperText>{meta.error}</FormHelperText>
                </Box>
              )}
            </FormControl>
          </>
        );
      }}
    </Field>
  );
}

export default FormRadioGroupDetails;
