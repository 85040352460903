import {
  Box,
  Typography,
  Container,
  Select,
  MenuItem,
  Divider,
  ButtonBase,
} from '@material-ui/core';
import React, { useState, useContext, useEffect } from 'react';
import AuthLayout from 'src/components/AuthLayout';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import ROUTES from 'src/constants/routes';
import useStyles from './Layout.styles';
import { ConfigContext } from 'src/contexts';
import { EnhancedSkeleton } from 'src/components/Enhanced';
import ExpandMore from '@material-ui/icons/ExpandMore';
import BusinessIcon from '@material-ui/icons/Business';
import LoadingOverlay from 'react-loading-overlay';
import ClipLoader from 'react-spinners/ClipLoader';
import useBreakpoints from 'src/hooks/useBreakpoints';
import CompanyDropdown from 'src/components/CompanyDropdown';

const Layout = ({ children }) => {
  const classes = useStyles();
  const { smBelow } = useBreakpoints();
  const {
    cartList,
    confirmationDialog,
    company,
    companyList,
    setCompany,
    companyLoading,
  } = useContext(ConfigContext);
  const [openCompany, setOpenCompany] = useState(false);
  const [othersCompanyList, setOthersCompanyList] = useState([]);
  const [collaborationCompanyList, setCollaborationCompanyList] = useState([]);

  useEffect(() => {
    const othersList = companyList.filter(
      (c) => c.company_id !== company.company_id,
    );
    setOthersCompanyList(othersList);
  }, [company]);

  function truncate(str, num) {
    if (str) return str.length > num ? str.substring(0, num) + '...' : str;
  }

  const handleCloseCompany = () => {
    setOpenCompany(false);
  };

  return (
    <LoadingOverlay
      active={companyLoading}
      spinner={<ClipLoader color="white" />}
      styles={{
        wrapper: {
          overflow: companyLoading ? 'hidden' : 'initial',
        },
      }}
    >
      {companyLoading ? (
        <div
          style={{
            width: '100%',
          }}
        >
          <EnhancedSkeleton
            layout={[
              {
                loaders: [{ variant: 'rect', height: 100, width: '100%' }],
                repeat: 5,
                mt: 4.25,
              },
            ]}
          />
        </div>
      ) : (
        <AuthLayout
          contentStyle={{
            minWidth: 0,
            minHeight: 0,
          }}
        >
          <Container
            maxWidth="xl"
            classes={{
              root: classes.containerRoot,
            }}
          >
            <Box
              px={4}
              py={2}
              display="flex"
              flexDirection="column"
              style={{ minHeight: '85%' }}
            >
              <Box
                display="flex"
                flexDirection={smBelow ? 'column' : 'row'}
                width="100%"
                pb={2}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  pt={1}
                >
                  <Typography variant="h1">Documents</Typography>
                  <BreadcrumbsNav
                    pathList={[
                      { path: 'Home', route: ROUTES.DASHBOARD },
                      {
                        path: 'Corporate Secretary',
                      },
                      {
                        path: 'Document',
                        color: 'primary',
                      },
                    ]}
                  />
                </Box>
                <div style={{ flexGrow: 1 }} />
                <CompanyDropdown />
              </Box>

              {children}
            </Box>
          </Container>
        </AuthLayout>
      )}
    </LoadingOverlay>
  );
};

export default Layout;
