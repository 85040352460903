import { Box, Container } from '@material-ui/core';
import React from 'react';
import AuthLayout from 'src/components/AuthLayout';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => {
  return {
    containerRoot: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  };
});

const ContentLayout = ({ children, header }) => {
  const classes = useStyles();

  return (
    <AuthLayout
      contentStyle={{
        minWidth: 0,
        minHeight: 0,
      }}
    >
      <Container
        maxWidth="xl"
        classes={{
          root: classes.containerRoot,
        }}
      >
        <Box
          px={4}
          py={2}
          display="flex"
          flexDirection="column"
          style={{ minHeight: '85%' }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            pb={2}
          >
            {header}
          </Box>

          {children}
        </Box>
      </Container>
    </AuthLayout>
  );
};

export default ContentLayout;
