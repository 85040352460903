import React, { useState, useContext, useEffect, useRef } from 'react';
import {
  Typography,
  Box,
  ButtonBase,
  Paper,
  Grid,
  makeStyles,
  Divider,
  Tabs,
  Tab,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  IconButton,
  TableHead,
  TableRow,
  ListItem,
  ListItemText,
  Button,
  Checkbox,
  TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useTheme } from '@material-ui/core/styles';
import { useHistory, useParams, Link } from 'react-router-dom';
import ROUTES from 'src/constants/routes';
import useButtonStyle from 'src/styles/button';
import MzButton from 'src/components/MzButton';
import { Formik, Form, FieldArray } from 'formik';
import FormTextField from 'src/components/FormTextField/FormTextField.js';
import * as Yup from 'yup';
import useBreakpoints from 'src/hooks/useBreakpoints';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import AlertBox from 'src/components/AlertBox';
import fourSteps from 'src/assets/requests/4steps.png';
import Stepper from 'src/components/Stepper';
import FormDropdown from 'src/components/FormDropdown/FormDropdown.js';
import { FormFileDropzone } from 'src/components/Form';
import DetailLabel from 'src/components/DetailLabel';
import FormDropdownTextField from 'src/components/FormDropdownTextField/FormDropdownTextField.js';
import GetStartedLayout from 'src/components/Corpsec/Requests/GetStartedLayout';
import { useProvideShareholder } from 'src/hooks/useShareholders';
import { useProvideRequest } from 'src/hooks/useRequests';
import { useProvideProduct } from 'src/hooks/useProduct';
import companySvc from 'src/services/company';
import { ConfigContext } from 'src/contexts';
import useSnackbar from 'src/hooks/useSnackbar';
import { S3UploadAndInsertDocuments } from 'src/utils/aws';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import TodayIcon from '@material-ui/icons/Today';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { EnhancedIcon } from 'src/components/Enhanced';
import EnhancedTextField from 'src/components/EnhancedTextField/EnhancedTextField';
import DottedLabel from 'src/components/DottedLabel';
import RowTable from 'src/components/RowTable';
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import CloseIcon from '@material-ui/icons/Close';

const tempSteps = [
  {
    id: 1,
    label: 'Share Capital',
  },
  {
    id: 2,
    label: 'Select Shareholders',
  },
  {
    id: 3,
    label: 'Allotment',
  },
];

const currencyList = [
  { id: 1, label: 'EUR' },
  { id: 2, label: 'GBP' },
  { id: 3, label: 'JPY' },
  { id: 4, label: 'SGD' },
  { id: 5, label: 'USD' },
];

const useStyles = makeStyles((theme) => ({
  fontWeight: {
    fontWeight: 600,
  },
}));

const AllotmentShares = ({ toggleShowMenu }) => {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const { smBelow } = useBreakpoints();
  const buttonClasses = useButtonStyle();
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [countryCode, setCountryCode] = useState('SG');
  const [proofOfCapital, setProofOfCapital] = useState([]);
  const [allotmentShareholders, setAllotmentShareholders] = useState([]);
  const [shareholderOptions, setShareholderOptions] = useState([]);
  const [sharesNumber, setSharesNumber] = useState();
  const [paidupCapital, setPaidupCapital] = useState();
  const [currency, setCurrency] = useState(currencyList[3].label);
  const [selectedSH, setSelectedSH] = useState([]);

  const {
    addCart,
    user,
    cartSubmitting,
    setConfirmationDialog,
    company,
  } = useContext(ConfigContext);
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { ReadProduct, product, productError } = useProvideProduct();
  const {
    GetShareholderByUserID,
    shareholderError,
    shareholder,
    shareholders,
    newshareholder,
    ListShareholders,
    ApplyNewShareholder,
  } = useProvideShareholder();
  const {
    CreateShareAllotmentApplication,
    requestSubmitting,
    requestError,
  } = useProvideRequest();
  const [touched, setTouched] = useState(false);
  const submitRef = useRef(null);

  const step2validationSchema = Yup.object({
    currency: Yup.string().required('Currency is required'),
    ordinaryshares: Yup.string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .required('Ordinary Shares is required'),
    amount: Yup.string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .required('Amount is required'),
    capitalFiles: Yup.array().min(1),
  });

  const step3validationSchema = Yup.object().shape({
    new_shareholders: Yup.array().of(
      Yup.object().shape({
        new_shares: Yup.string()
          .matches(/^[0-9]+$/, 'Must be only digits')
          .required('New Shares is required')
          .test('checkBalance', '', function () {
            if (calcShareBalance() < 0) {
              return this.createError({
                message: 'Balance cannot be negative',
              });
            }
            return true;
          }),
        new_capital: Yup.string()
          .matches(/^[0-9]+$/, 'Must be only digits')
          .required('New Capital is required')
          .test('checkBalance', '', function () {
            if (calcCapitalBalance() < 0) {
              return this.createError({
                message: 'Balance cannot be negative',
              });
            }
            return true;
          }),
      }),
    ),
  });

  const newShareholderValidationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    nric_passport_no: Yup.string().required('ID is required'),
    mobile_number: Yup.string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .required('Mobile Number is required'),
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
    identityFiles: Yup.array().when('tabValue', {
      is: () => tabValue === 0,
      then: Yup.array().min(1),
    }),
    residencyFiles: Yup.array().when('tabValue', {
      is: () => tabValue === 1,
      then: Yup.array().min(1),
    }),
    passportFiles: Yup.array().when('tabValue', {
      is: () => tabValue === 1,
      then: Yup.array().min(1),
    }),
  });

  const getStarted = {
    one: {
      content: [
        'Issue new ordinary shares, fully payable in cash in SGD, USD, GBP, EUR or JPY',
        <Typography
          key={''}
          align="justify"
          variant="body2"
          color="textPrimary"
          display="inline"
        >
          {'Allot new shares to new or existing'}
          {/* <Typography
            align="justify"
            variant="body2"
            color="textPrimary"
            display="inline"
            className={classes.fontWeight}
          > */}
          {' Individual '}
          {/* </Typography> */}
          {'shareholders'}
        </Typography>,
        <span key={'2'}>
          {`To issue other types of shares and currencies, please go to `}
          <Link to={ROUTES.CORPSEC_REQUESTS_OTHER_REQUEST}>Other Requests</Link>
        </span>,
      ],
    },
    two: {
      content: [
        'Number of ordinary shares to issue and the paid-up capital',
        'A copy of the bank statement or receipt (JPG/PNG/PDF format)',
        'Shareholder particulars and a copy of their NRIC/passport (new shareholders only)',
        'Allotment details (number of new shares and the capital for each shareholder)',
      ],
    },
    three: {
      content: [
        'Create Request',
        'Meyzer360 Review',
        'Sign Resolution',
        'ACRA Filing',
      ],
    },
    four: {
      content: ['Basic Plan: SGD100', 'Unlimited Plan: SGD100'],
    },
    five: {
      content: [
        'Fill up form: < 5 minutes',
        'Average processing time: 10-15 days',
      ],
    },
  };

  useEffect(() => {
    // get latest product
    ReadProduct(20);
    GetShareholderByUserID(user.user_id);
  }, []);

  useEffect(() => {
    if (company.company_id) {
      ListShareholders(company.company_id);
    }
  }, [company.company_id]);

  useEffect(() => {
    if (requestError) {
      showErrorSnackbar(`Error submitting application. ${requestError}`);
    }
  }, [requestError]);

  useEffect(() => {
    if (shareholders.length > 0) {
      setShareholderOptions(populateShareholderOptions(shareholders));
    }
  }, [shareholders]);

  const getShareholderByCompany = (shareholders = []) => {
    return shareholders.find((sh) => sh.company_id === company.company_id);
  };

  const populateShareholderOptions = (shareholders = []) => {
    return shareholders.map((sh) => ({
      label: sh.Name,
      value: sh.shareholder_id,
    }));
  };

  const handleTabChange = (event, newValue) => {
    setTouched(false);
    setTabValue(newValue);
  };

  const calcShareBalance = () => {
    return (
      sharesNumber -
      allotmentShareholders.reduce((acc, shareholder) => {
        return acc + Number(shareholder.new_shares);
      }, 0)
    );
  };

  const calcCapitalBalance = () => {
    return (
      paidupCapital -
      allotmentShareholders.reduce((acc, shareholder) => {
        return acc + Number(shareholder.new_capital);
      }, 0)
    );
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        style={{ width: '100%' }}
        hidden={value !== index}
        {...other}
      >
        {value === index && <>{children}</>}
      </div>
    );
  };

  const formikOnSubmitStep2 = async (values, actions) => {
    setSharesNumber(values.ordinaryshares);
    setPaidupCapital(values.amount);
    setCurrency(values.currency);
    setProofOfCapital(values.capitalFiles);
    setStep(3);
  };

  const formikOnSubmitNewShareholder = async (values, actions) => {
    setLoading(true);
    if (values.identityFiles.length > 0) {
      const identityFilesID = await S3UploadAndInsertDocuments(
        values.identityFiles[0],
        76,
        6,
        'officer_application_id',
      );
      values.proof_identity_attachment_id = identityFilesID.document_id;
    }

    if (values.residencyFiles.length > 0) {
      const residencyFilesID = await S3UploadAndInsertDocuments(
        values.residencyFiles[0],
        112,
        6,
        'officer_application_id',
      );
      values.proof_residency_attachment_id = residencyFilesID.document_id;
    }

    if (values.passportFiles.length > 0) {
      const passportFilesID = await S3UploadAndInsertDocuments(
        values.passportFiles[0],
        77,
        6,
        'officer_application_id',
      );
      values.passport_attachment_id = passportFilesID.document_id;
    }

    allotmentShareholders.push({
      company_id: company.company_id,
      company_application_status_id: 11,
      is_foreigner: tabValue == 0 ? 0 : 1,
      shareholder_name: values.name,
      identity_id: values.nric_passport_no,
      mobile_number: values.mobile_number,
      email: values.email,
      proof_identity_attachment_id: values.proof_identity_attachment_id,
      passport_attachment_id: values.passport_attachment_id,
      proof_residency_attachment_id: values.proof_residency_attachment_id,
      created_by: user.user_id,
      new_shares: '',
      new_capital: '',
      is_new_shareholder: 1,
    });
    setAllotmentShareholders(allotmentShareholders);
    setLoading(false);
    actions.resetForm();
    setStep(4);

    // let res = await ApplyNewShareholder({
    //   company_id: company.company_id,
    //   company_application_status_id: 11,
    //   is_foreigner: tabValue == 0 ? 0 : 1,
    //   shareholder_name: values.name,
    //   identity_id: values.nric_passport_no,
    //   mobile_number: values.mobile_number,
    //   email: values.email,
    //   proof_identity_attachment_id: values.proof_identity_attachment_id,
    //   passport_attachment_id: values.passport_attachment_id,
    //   proof_residency_attachment_id: values.proof_residency_attachment_id,
    //   created_by: user.user_id,
    // });
    // setLoading(false);
    // if (res.status === 'success') {
    //   actions.resetForm();
    //   setStep(3);
    // }
  };

  const formikOnSubmit = async (values, actions) => {
    if (calcShareBalance() < 0) {
      return showErrorSnackbar(`Share exceed available amount to allocate.`);
    }
    if (calcCapitalBalance() < 0) {
      return showErrorSnackbar(`Capital exceed available amount to allocate.`);
    }
    setSubmitting(true);
    values.company_id = company.company_id;
    values.company_application_status_id = 11;
    values.created_by = user.user_id;

    values.no_shares = sharesNumber;
    values.issued_paid_up_amount = paidupCapital;
    values.currency = currency;
    // values.new_shares = allotmentNewShare;
    // values.new_capital = allotmentNewCapital;
    // values.allotment_shareholder_id = allotmentShareholderID;
    // if (newshareholder) {
    //   values.is_new_shareholder = 1;
    //   values.allotment_shareholder_id = null;
    //   values = { ...values, ...newshareholder };
    // }

    // actions.setFieldValue('shareholders', allotmentShareholders);

    try {
      if (proofOfCapital.length > 0) {
        const capitalFile = await S3UploadAndInsertDocuments(
          proofOfCapital[0],
          110,
          4,
          'customer_due_diligence_id',
        );
        values.proof_capital_attachment_id = capitalFile.document_id;
      }
    } catch (error) {
      showErrorSnackbar(`Error uploading document`);
      return;
    }
    let res;
    try {
      res = await CreateShareAllotmentApplication(values);
    } catch (error) {
      showErrorSnackbar('Error submitting application');
    }
    if (res?.status === 'success') {
      try {
        res = await addCart({
          price: product.price,
          product_id: product.id,
          quantity: 1,
          user_id: user.user_id,
          product_detail_id:
            res.body.applications.shares_allotment_application_id,
          company_id: company.company_id,
          company_name: company.company_name,
          company_registration_no: company.company_registration_no,
          company_application_id: res.body.applications.company_application_id,
        });
        setSubmitting(false);
        if (res.status === 'success') {
          actions.resetForm();
          setConfirmationDialog({
            isVisible: true,
            title: 'Item added to cart',
            type: 'success',
            cancelButtonLabel: 'Back to Request',
            confirmButtonLabel: 'View your cart',
            confirmButtonAction: () => {
              history.push(ROUTES.PAYMENTCART);
              setConfirmationDialog({
                isVisible: false,
              });
            },
            cancelButtonAction: () => {
              history.push(ROUTES.CORPSEC_REQ);
              setConfirmationDialog({
                isVisible: false,
              });
            },
          });
        } else {
          showErrorSnackbar('Error adding to cart');
        }
      } catch (error) {
        showErrorSnackbar('Error adding to cart');
      }
    } else {
      showErrorSnackbar('Error submitting application');
    }
    setSubmitting(false);
  };

  const stepTwo = () => {
    return (
      <Formik
        initialValues={{
          currency: currency,
          ordinaryshares: sharesNumber,
          amount: paidupCapital,
          capitalFiles: proofOfCapital,
        }}
        onSubmit={formikOnSubmitStep2}
        validationSchema={step2validationSchema}
      >
        {(props) => (
          <Form style={{ width: '100%' }}>
            <FormDropdown
              autoCompleteStyle={{ width: smBelow ? '100%' : '50%' }}
              name="currency"
              onChange={(event, newValue) => {
                props.setFieldValue('currency', newValue?.label);
              }}
              optionList={currencyList}
              labelText="Select Currency"
              placeholder="Select One"
              fullWidth
              containerWidth="50%"
              shrink
              variant="outlined"
              defaultValue={(() => {
                return currencyList.find(
                  (cur) => cur.label === props.values.currency,
                );
              })()}
            />
            <FormTextField
              name="ordinaryshares"
              labelText="No. of Ordinary Shares *"
              fullWidth={smBelow ? true : false}
              variant="outlined"
              placeholder="0"
            />
            <FormTextField
              name="amount"
              labelText="Amount of Issued/ Paid Up Share Capital *"
              fullWidth={smBelow ? true : false}
              shrink
              variant="outlined"
              placeholder="0"
            />
            <FormFileDropzone
              containerWidth={smBelow ? '100%' : '50%'}
              name="capitalFiles"
              labelText="Proof of Capital (Bank of Statement or Bank Receipt) *"
            />
            <Box display="flex" pt={3} alignItems="flex-start">
              <MzButton
                // disabled={!(props.isValid && props.dirty)}
                type="submit"
                onClick={() => {}}
                title={'Save & Proceed'}
              />
            </Box>
          </Form>
        )}
      </Formik>
    );
  };

  const stepThree = () => {
    return (
      <>
        <DottedLabel label="Currency:" value={currency} />
        <>
          <Grid container>
            <Paper
              variant="outlined"
              square
              style={{
                width: '100%',
                padding: '10px',
              }}
            >
              <Grid container item xs={12} spacing={3}>
                <Grid item xs={6} />
                <Grid item xs={3}>
                  <Typography variant="h5">New</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h5">Balance</Typography>
                </Grid>
              </Grid>
            </Paper>
            <RowTable
              label="No. of Shares"
              latest={sharesNumber}
              balance={calcShareBalance()}
            />
            <RowTable
              label="Issued/ Paid-up Capital"
              latest={paidupCapital}
              balance={calcCapitalBalance()}
            />
          </Grid>
        </>
        <Box py={3} />

        <Box
          display={'flex'}
          flexDirection={'row'}
          width={'100%'}
          style={{ gap: 20 }}
        >
          <Paper
            variant={'outlined'}
            style={{
              borderRadius: smBelow ? '0px' : '4px',
              flex: '1 1 0%',
            }}
          >
            <ListItem
              style={{
                backgroundColor: '#F5F5F5',
              }}
            >
              <ListItemText
                primaryTypographyProps={{
                  style: {
                    whiteSpace: 'normal',
                    fontWeight: 'bold',
                  },
                  variant: 'body2',
                }}
                primary={'Select Existing Shareholders'}
              />
            </ListItem>
            <Divider />
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              minHeight={'50px'}
              px={7}
              py={7}
              flex={1}
            >
              <GroupOutlinedIcon
                style={{
                  width: '60px',
                  height: '60px',
                  fill: 'black',
                  fillOpacity: '0.34',
                }}
              />
              <Autocomplete
                multiple
                onClose={() => {
                  if (allotmentShareholders.length > 0) {
                    setStep(4);
                  }
                }}
                value={selectedSH}
                options={shareholderOptions}
                disableCloseOnSelect
                getOptionLabel={(option) => option.label}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                      color="primary"
                    />
                    {option.label}
                  </React.Fragment>
                )}
                style={{ width: 400 }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Select" />
                )}
                onChange={(event, values = [], reason) => {
                  event.preventDefault();
                  let selectedShareholders = [];
                  setSelectedSH(values);
                  if (reason === 'select-option') {
                    values.forEach((selectedShareholder) => {
                      const selectedSHData = shareholders.find(
                        (sh) => sh.shareholder_id === selectedShareholder.value,
                      );
                      const inTransferList = allotmentShareholders.find(
                        (tsh) => tsh.id === selectedSHData.shareholder_id,
                      );
                      if (inTransferList) return;

                      return selectedShareholders.push({
                        id: selectedSHData.shareholder_id,
                        company_id: company.company_id,
                        company_application_status_id: 11,
                        shareholder_name: selectedSHData.Name,
                        created_by: user.user_id,
                        new_shares: '',
                        new_capital: '',
                        is_new_shareholder: 0,
                      });
                    });
                    selectedShareholders = [
                      ...selectedShareholders,
                      ...allotmentShareholders,
                    ];
                  }
                  if (reason === 'remove-option' || reason === 'clear') {
                    // remove from selection
                    selectedShareholders = allotmentShareholders.filter(
                      (tsh) => {
                        if (tsh.is_new_shareholder === 1) return true;
                        return values.find((sh) => sh.value === tsh.id);
                      },
                    );
                  }

                  setAllotmentShareholders([...selectedShareholders]);
                }}
              />
            </Box>
          </Paper>

          <Paper
            variant={'outlined'}
            style={{
              borderRadius: smBelow ? '0px' : '4px',
              flex: '1 1 0%',
            }}
          >
            <ListItem
              style={{
                backgroundColor: '#F5F5F5',
              }}
            >
              <ListItemText
                primaryTypographyProps={{
                  style: {
                    whiteSpace: 'normal',
                    fontWeight: 'bold',
                  },
                  variant: 'body2',
                }}
                primary={'Add New Shareholders'}
              />
            </ListItem>
            <Divider />
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              minHeight={'50px'}
              flex={1}
              px={7}
              py={7}
            >
              <GroupAddOutlinedIcon
                style={{
                  width: '60px',
                  height: '60px',
                  fill: 'black',
                  fillOpacity: '0.34',
                }}
              />
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                size="large"
                onClick={() => {
                  setStep(5);
                }}
              >
                Add New
              </Button>
            </Box>
          </Paper>
        </Box>
      </>
    );
  };

  const stepFour = () => {
    return (
      <>
        <DottedLabel label="Currency:" value={currency} />
        <>
          <Grid container>
            <Paper
              variant="outlined"
              square
              style={{
                width: '100%',
                padding: '10px',
              }}
            >
              <Grid container item xs={12} spacing={3}>
                <Grid item xs={6} />
                <Grid item xs={3}>
                  <Typography variant="h5">New</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h5">Balance</Typography>
                </Grid>
              </Grid>
            </Paper>
            <RowTable
              label="No. of Shares"
              latest={sharesNumber}
              balance={calcShareBalance()}
            />
            <RowTable
              label="Issued/ Paid-up Capital"
              latest={paidupCapital}
              balance={calcCapitalBalance()}
            />
          </Grid>
        </>
        <Box py={3} />
        <Formik
          initialValues={{
            allotment_shareholder_id: '',
            new_shareholders: [],
          }}
          onSubmit={formikOnSubmit}
          validationSchema={step3validationSchema}
          enableReinitialize={true}
        >
          {(props) => (
            <Form style={{ width: '100%' }}>
              <Box
                p={2}
                display="flex"
                flexDirection={smBelow ? 'column' : 'row'}
                justifyContent="space-between"
                alignItems={smBelow ? 'flex-start' : 'center'}
                width="100%"
              >
                <DottedLabel value="Allotment Details" />
                <ButtonBase
                  style={{ justifyContent: 'flex-start', color: '#A21D23' }}
                  onClick={() => {
                    setStep(5);
                  }}
                >
                  <Typography variant="h6">Add New Shareholder</Typography>
                  <ChevronRightIcon />
                </ButtonBase>
              </Box>
              <Paper variant="outlined">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>Shareholder</TableCell>
                      <TableCell>New Share</TableCell>
                      <TableCell>New Capital</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <FieldArray name="new_shareholders">
                      {() => (
                        <>
                          {allotmentShareholders.map((shareholder, index) => (
                            <TableRow key={shareholder.id}>
                              <TableCell>
                                <IconButton
                                  onClick={() => {
                                    allotmentShareholders.splice(index, 1);
                                    setAllotmentShareholders([
                                      ...allotmentShareholders,
                                    ]);
                                    if (shareholder.is_new_shareholder === 0) {
                                      // remove from selected option
                                      const indexToRemove = selectedSH.findIndex(
                                        (ssh) => {
                                          return ssh.value === shareholder.id;
                                        },
                                      );
                                      if (indexToRemove !== -1) {
                                        selectedSH.splice(indexToRemove, 1);
                                        setSelectedSH(selectedSH);
                                      }
                                    }
                                  }}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </TableCell>
                              <TableCell>
                                {shareholder.shareholder_name}
                              </TableCell>
                              <TableCell>
                                <FormTextField
                                  name={`new_shareholders.${index}.new_shares`}
                                  variant="outlined"
                                  value={shareholder.new_shares}
                                  onChange={(event) => {
                                    props.setFieldValue(
                                      `new_shareholders.${index}.new_shares`,
                                      event.target.value,
                                    );
                                    allotmentShareholders[index].new_shares =
                                      event.target.value;
                                    setAllotmentShareholders([
                                      ...allotmentShareholders,
                                    ]);
                                    // setAllotmentNewShare(event.target.value);
                                  }}
                                  // width={smBelow ? '100%' : '33%'}
                                  fullWidth
                                  paddingBottom={false}
                                />
                              </TableCell>
                              <TableCell>
                                <FormTextField
                                  name={`new_shareholders.${index}.new_capital`}
                                  variant="outlined"
                                  value={shareholder.new_capital}
                                  onChange={(event) => {
                                    props.setFieldValue(
                                      `new_shareholders.${index}.new_capital`,
                                      event.target.value,
                                    );
                                    allotmentShareholders[index].new_capital =
                                      event.target.value;
                                    setAllotmentShareholders([
                                      ...allotmentShareholders,
                                    ]);
                                    // setAllotmentNewCapital(event.target.value);
                                  }}
                                  // width={smBelow ? '100%' : '33%'}
                                  fullWidth
                                  paddingBottom={false}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </>
                      )}
                    </FieldArray>
                  </TableBody>
                </Table>
              </Paper>
              <Box p={2} />
              <Box
                display="flex"
                justifyContent={'flex-end'}
                style={{ gap: '10px' }}
              >
                <Button
                  variant="contained"
                  size="large"
                  style={{
                    backgroundColor: '#9E9E9E',
                    color: 'white',
                    textTransform: 'initial',
                  }}
                  onClick={() => {
                    setStep(3);
                  }}
                >
                  Back to Select Shareholders
                </Button>
                <MzButton
                  disabled={
                    calcShareBalance() !== 0 || calcCapitalBalance() !== 0
                  }
                  type="submit"
                  onClick={async () => {
                    props.setFieldValue(
                      'new_shareholders',
                      [...allotmentShareholders],
                      true,
                    );
                    props.setFieldTouched('new_shareholders', true, true);
                  }}
                  title={'Submit'}
                  loading={submitting}
                />
              </Box>
            </Form>
          )}
        </Formik>
      </>
    );
  };

  const addNewShareholder = () => {
    return (
      <>
        <Typography variant="h6" align="left" className={classes.fontWeight}>
          Fill in information for New Shareholder
        </Typography>
        <Box p={1} />
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          style={{
            borderBottom: '0.01rem solid #E4E4E4',
            paddingLeft: theme.spacing(smBelow ? 3 : 12),
          }}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab
            label="Local"
            style={{
              textTransform: 'initial',
              fontSize: 16,
            }}
          />
          <Tab
            label="Foreigner"
            style={{
              textTransform: 'initial',
              fontSize: 16,
            }}
          />
        </Tabs>
        <Box p={1} />
        <TabPanel value={tabValue} index={0}>
          {renderForm()}
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          {renderForm()}
        </TabPanel>
      </>
    );
  };

  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          company_role_id: 5,
          name: '',
          nric_passport_no: '',
          email: '',
          mobile_number: '',
          identityFiles: [],
          residencyFiles: [],
          passportFiles: [],
        }}
        onSubmit={formikOnSubmitNewShareholder}
        validationSchema={newShareholderValidationSchema}
        innerRef={submitRef}
      >
        {(props) => (
          <Box p={3} pl={smBelow ? 3 : 12}>
            <Form>
              <FormTextField
                fullWidth={smBelow ? true : false}
                name="name"
                labelText={
                  tabValue === 0
                    ? 'NAME (as in NRIC/FIN) *'
                    : 'NAME (as in PASSPORT) *'
                }
                placeholder="Name"
                shrink
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonOutlineIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <FormTextField
                fullWidth={smBelow ? true : false}
                name="nric_passport_no"
                labelText="ID *"
                placeholder="ID"
                shrink
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CreditCardIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <FormDropdownTextField
                name="mobile_number"
                labelText="Mobile Number *"
                placeholder="91234567"
                containerWidth={smBelow ? '100%' : '50%'}
                fullWidth
                shrink
                variant="outlined"
                showFlag={false}
                onValueChange={(event, newValue) => {
                  setCountryCode(newValue.code);
                }}
                countryCode={countryCode}
              />
              <FormTextField
                fullWidth={smBelow ? true : false}
                name="email"
                labelText="Email Address *"
                placeholder="Email Address"
                shrink
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailOutlineIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {tabValue === 0 && (
                <FormFileDropzone
                  containerWidth={smBelow ? '100%' : '50%'}
                  name="identityFiles"
                  labelText="Proof of Identity *"
                />
              )}

              {tabValue === 1 && (
                <Box
                  display="flex"
                  flexDirection={smBelow ? 'column' : 'row'}
                  style={{
                    gap: '20px',
                  }}
                >
                  <FormFileDropzone
                    containerWidth={smBelow ? '100%' : '50%'}
                    name="passportFiles"
                    labelText="Copy of Passport *"
                    manualTouch={touched}
                  />
                  <FormFileDropzone
                    containerWidth={smBelow ? '100%' : '50%'}
                    name="residencyFiles"
                    labelText="Proof of Residency (Must be in English) *"
                    manualTouch={touched}
                  />
                </Box>
              )}

              <Box display="flex" pt={3} justifyContent="flex-start">
                <MzButton
                  // disabled={!(props.isValid && props.dirty)}
                  fullWidth={smBelow ? true : false}
                  type="submit"
                  title={'SAVE'}
                  onClick={async () => {
                    if (
                      tabValue == 1 &&
                      (props.values.passportFiles.length == 0 ||
                        props.values.residencyFiles.length == 0)
                    ) {
                      await setTouched(true);
                      submitRef.current.handleSubmit();
                    }
                  }}
                  loading={loading}
                />
              </Box>
            </Form>
          </Box>
        )}
      </Formik>
    );
  };

  return (
    <>
      <GetStartedLayout
        toggleShowMenu={toggleShowMenu}
        step={step}
        setStep={setStep}
        getStarted={getStarted}
        header={
          <>
            <Typography variant="h2">
              {step === 1 && 'Allotment of Shares'}
              {(step === 2 || step === 3 || step === 4) && 'Allotment Details'}
            </Typography>
            <BreadcrumbsNav
              pathList={[
                { path: 'Requests', route: ROUTES.CORPSEC_REQ },
                {
                  path: 'Change Share Information',
                  route: ROUTES.CORPSEC_REQ_CHANGE_SHARE_INFORMATION,
                },
                {
                  path: 'Allotment of Shares',
                  color: 'primary',
                },
              ]}
            />
          </>
        }
      >
        {step !== 1 && (
          <Box px={4} py={4} style={{ borderTop: '0.01rem solid #E4E4E4' }}>
            {(step === 2 || step === 3 || step === 4) && (
              <Stepper
                steps={tempSteps}
                providedStep={step - 2}
                setProvidedStep={(step) => {
                  setStep(step + 2);
                }}
              />
            )}
            {step === 2 && stepTwo()}
            {step === 3 && stepThree()}
            {step === 4 && stepFour()}
            {step === 5 && addNewShareholder()}
          </Box>
        )}
        <Box py={5} />
      </GetStartedLayout>
    </>
  );
};

export default AllotmentShares;
