import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import React from 'react';
import { EnhancedIcon } from 'src/components/Enhanced';
import { useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import useBreakpoints from 'src/hooks/useBreakpoints';

const NavItem = ({
  selected,
  setSelected = true,
  label,
  secondaryLabel,
  icon,
  route,
  onClick,
  onHideMenu,
  children,
  textVariant,
  textColor = 'default',
  selectedTextColor,
  textStyle = {},
  itemStyle = {},
  iconProps = {},
  ListItemIconStyle = {},
}) => {
  const theme = useTheme();
  const history = useHistory();
  const { smBelow } = useBreakpoints();
  return (
    <ListItem
      selected={setSelected && selected}
      style={{
        ...itemStyle,
        borderRadius: '5px',
      }}
      button
      key={label}
      onClick={(e) => {
        e.preventDefault();
        if (onClick) return onClick();
        history.push(route);
        if (smBelow && onHideMenu) return onHideMenu();
      }}
    >
      <ListItemIcon style={ListItemIconStyle}>
        {icon && (
          <EnhancedIcon
            icon={icon}
            iconStyle={{
              [selected && 'fill']: theme.palette.primary.main,
              ...iconProps?.style,
            }}
            {...iconProps}
          />
        )}
      </ListItemIcon>
      <ListItemText
        primary={label}
        secondary={secondaryLabel}
        primaryTypographyProps={{
          color: selected ? 'primary' : textColor,
          variant: textVariant ? textVariant : 'h5',
          style: {
            whiteSpace: 'normal',
          },
        }}
        secondaryTypographyProps={{
          color: selected ? 'primary' : textColor,
          variant: textVariant ? textVariant : 'h5',
        }}
        style={textStyle}
      />
      {children}
    </ListItem>
  );
};

export default NavItem;
