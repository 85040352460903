import {
  Box,
  Typography,
  Container,
  Select,
  MenuItem,
  Divider,
  ButtonBase,
  Paper,
} from '@material-ui/core';
import React, { useState, useContext, useEffect } from 'react';
import AuthLayout from 'src/components/AuthLayout';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import ROUTES from 'src/constants/routes';
import useBreakpoints from 'src/hooks/useBreakpoints';
import { ConfigContext } from 'src/contexts';
import { EnhancedSkeleton } from 'src/components/Enhanced';
import ExpandMore from '@material-ui/icons/ExpandMore';
import BusinessIcon from '@material-ui/icons/Business';
import LoadingOverlay from 'react-loading-overlay';
import ClipLoader from 'react-spinners/ClipLoader';
import useStyles from './CompanyDropdown.styles';

const CompanyDropdown = ({ children }) => {
  const current = new Date();
  const { smBelow } = useBreakpoints();
  const classes = useStyles();
  const {
    cartList,
    confirmationDialog,
    company,
    companyList,
    setCompany,
    companyLoading,
  } = useContext(ConfigContext);
  const [openCompany, setOpenCompany] = useState(false);
  const [othersCompanyList, setOthersCompanyList] = useState([]);
  const [collaborationCompanyList, setCollaborationCompanyList] = useState([]);

  useEffect(() => {
    const othersList = companyList.filter(
      (c) => c.company_id !== company.company_id,
    );
    setOthersCompanyList(othersList);
  }, [company]);

  function truncate(str, num) {
    if (str) return str.length > num ? str.substring(0, num) + '...' : str;
  }

  const handleCloseCompany = () => {
    setOpenCompany(false);
  };

  return (
    <Box>
      <Typography
        variant="h5"
        align={smBelow ? 'center' : 'left'}
        style={{ marginTop: smBelow ? '25px' : 0 }}
      >
        Selected Company:
      </Typography>

      <Box display="flex" alignItems="center" className={classes.companyBox}>
        <BusinessIcon />
        <Box pr={1} />
        <Select
          value={company.company_id}
          open={openCompany}
          onClose={handleCloseCompany}
          onOpen={() => setOpenCompany(true)}
          IconComponent={ExpandMore}
          disableUnderline
          className={classes.companyselect}
        >
          <Typography variant="caption" className={classes.companyTitle}>
            Current Company
          </Typography>
          <MenuItem className={classes.companyItem} value={company.company_id}>
            <Typography variant="h5" noWrap>
              {company.company_name}
            </Typography>
          </MenuItem>

          {othersCompanyList.length > 0 && (
            <>
              <Divider variant="middle" />
              <Typography variant="caption" className={classes.companyTitle}>
                Others
              </Typography>
              {othersCompanyList.map((item, index) => (
                <MenuItem
                  key={item.company_name}
                  value={item.company_id}
                  className={classes.companyItem}
                  onClick={() => {
                    setCompany(item.company_id);
                    handleCloseCompany();
                  }}
                >
                  <Typography variant="h5" noWrap>
                    {truncate(item.company_name, 25)}
                  </Typography>
                </MenuItem>
              ))}
            </>
          )}

          {collaborationCompanyList.length > 0 && (
            <>
              <Divider variant="middle" />
              <Typography variant="caption" className={classes.companyTitle}>
                Collaboration
              </Typography>
              {collaborationCompanyList.map((item, index) => (
                <MenuItem
                  key={item.company_name}
                  value={item.company_id}
                  className={classes.companyItem}
                  onClick={() => {
                    setCompany(item.company_id);
                    handleCloseCompany();
                  }}
                >
                  <Typography variant="h5" noWrap>
                    {truncate(item.company_name, 25)}
                  </Typography>
                </MenuItem>
              ))}
            </>
          )}

          <Divider variant="middle" />
          <ButtonBase>
            <Typography variant="h5" className={classes.addNewCompany}>
              + Add New Company Account
            </Typography>
          </ButtonBase>
        </Select>
      </Box>
    </Box>
  );
};

export default CompanyDropdown;
