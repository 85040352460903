import { GetErrorMessage } from 'src/utils/error';
import axios from 'axios';

export const APIRequest = async ({
  setError,
  setLoading,
  path,
  method,
  body,
  headers = {},
}) => {
  setLoading(true);
  setError(undefined);
  let res;
  try {
    res = await axios[method](path, body, {
      headers,
    });
    setLoading(false);
  } catch (err) {
    const errMsg = GetErrorMessage(err);
    setError(errMsg);
    setLoading(false);
    return {
      message: errMsg,
      status: 'rejected',
    };
  }
  // finally {
  //   setLoading(false);
  // }
  return {
    status: 'success',
    body: res.data,
  };
};
