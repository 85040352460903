import { useContext, useState, createContext } from 'react';
import { Config } from 'src/config';
import { APIRequest } from 'src/utils/api';
import companySvc from 'src/services/company';

const cartContext = createContext({
  error: '',
  loading: false,
  submitting: false,
  ListTasks: () => Promise.resolve({}),
});

export function useProvideTasks() {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [tasks, setTasksList] = useState([]);

  const ListTasks = async () => {
    const company = companySvc.getCompany();

    const res = await APIRequest({
      setError: setError,
      setLoading: setLoading,
      path: `${Config.apiURL}/company/tasks/company/${company.companyId}`,
      method: 'get',
    });
    if (res.status === 'success') {
      setTasksList(res.body.task);
    }
    return res;
  };

  return {
    error,
    loading,
    submitting,
    tasks,
    ListTasks,
  };
}

export const useCartContext = () => useContext(cartContext);

export function ProvideTasks({ children }) {
  const cart = useProvideTasks();
  return <cartContext.Provider value={cart}>{children}</cartContext.Provider>;
}
